import UserData from "../entities/userData"

export default class UserDataDTO {
    static fromJson (json: any): UserData {
		return new UserData(
			json.id,
            json.email,
            json.name,
            json.phone
		)
	}
}