export default class LoanQuiz {
    constructor(
        public isOwned: boolean | undefined = undefined,
        public ThirdPartyBondDegree: string | undefined = undefined,
        public isThirdParty: boolean | undefined = undefined,
        public isPublicServant: boolean | undefined = undefined,
        public registrationNumber: string | undefined = undefined,
        public averbId: string | undefined = undefined,
        public sphereOfAction: string | undefined = undefined,
        public agency: string | undefined = undefined,
    ) {}
}