import UserType from "./valueObjects/userType"

export default class User {
	constructor (
		public id: string = '',
		public email: string = '',
        public password: string = '',
		public emailWasVerified: boolean = false,
        public status: string = 'active',
        public userType: UserType = new UserType('unknown'),
        public mustChangePassword: boolean = false,
	) {}
}