import React, { useState, useEffect } from "react";
import "./App.scss";
import { createHashRouter, Navigate, RouterProvider } from "react-router-dom";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import CreateEnterprise from "./components/enterprise/CreateEnterprise";
import { PageNotFound } from "./components/PageNotFound";
import FAQ from "./components/FAQ";
import MainLayout from "./layout/MainLayout";
import Home from "./components/main/Home";
import Enterprises from "./components/enterprise/Enterprises";
import Proposals from "./components/proposal/Proposals";
import CreateProposal from "./components/proposal/CreateProposal";
import EnterprisesDetails from "./components/enterprise/EnterprisesDetails";
import ProposalDetails from "./components/proposal/ProposalDetails";
import Leads from "./components/leads/Leads";
import Scheduling from "./components/scheduling/Scheduling";
import ViabilitySpreadsheet from "./components/ViabilitySpreadsheet";
import Loading from "./components/Loading";

const MainApp = () => {
  const [headerTitle, setHeaderTitle] = React.useState("Home");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  const routes = [
    {
      path: "/",
      element: <Navigate to="/login" />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/esqueci-minha-senha",
      element: <ForgotPassword />,
    },
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <MainLayout
            headerTitle={headerTitle}
            setHeaderTitle={setHeaderTitle}
          />
        </ProtectedRoute>
      ),
      children: [
        { path: "/home", element: <Home /> },
        {
          path: "/empreendimentos",
          children: [
            { path: "novo", element: <CreateEnterprise /> },
            { path: "visualizar", element: <Enterprises /> },
            { path: ":id", element: <EnterprisesDetails /> },
          ],
        },
        {
          path: "/propostas",
          children: [
            { path: "criar", element: <CreateProposal /> },
            { path: "visualizar", element: <Proposals /> },
            { path: ":id", element: <ProposalDetails /> },
          ],
        },
        {
          path: "/leads",
          element: <Leads />,
        },
        {
          path: "/outros",
          children: [
            { path: "FAQ", element: <FAQ /> },
            { path: "Scheduling", element: <Scheduling /> },
            { path: "ViabilitySpreadsheet", element: <ViabilitySpreadsheet /> },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ];

  const router = createHashRouter(routes);

  return (
    <div>{isLoading ? <Loading /> : <RouterProvider router={router} />}</div>
  );
};

export default MainApp;
