interface ListSearchParams {
    searchTerm?: string;
    status?: string;
    orderBy?: string;
    limit: number;
    offset: number;
    fromDate?: number;
    toDate?: number;
    enterpriseId?: number;
}

export class ListSearch {
    public searchTerm: string | undefined;
    public status: string | undefined;
    public orderBy: string | undefined;
    public limit: number;
    public offset: number;
    public fromDate: number | undefined;
    public toDate: number | undefined;
    public enterpriseId: number | undefined;

    constructor({ searchTerm, status, orderBy, limit = 10, offset = 0, fromDate, toDate, enterpriseId }: ListSearchParams) {
        this.searchTerm = searchTerm;
        this.status = status;
        this.orderBy = orderBy;
        this.limit = limit;
        this.offset = offset;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.enterpriseId = enterpriseId;
    }
}