import User from "../entities/user"
import UserType from "../entities/valueObjects/userType"

export default class UserDTO {
    static fromJson (json: any): User {
		return new User(
			json.id || json.userId,
			json.email,
            json.password,
            json.emailWasVerified,
            json.status,
            new UserType(json.userType),
            json.mustChangePassword
		)
	}

	toJson (user: User): any {
		return {
			id: user.id,
			email: user.email,
            password: user.password,
            emailWasVerified: user.emailWasVerified,
            status: user.status,
            userType: user.userType.getUserType(),
            mustChangePassword: user.mustChangePassword
		}
	}
}