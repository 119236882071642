import ZipCode from "./valueObjects/zipCode"

export class Address {
	constructor (
		public id = 0,
		public city = '',
		public uf = '',
		public neighborhood = '',
		public street = '',
		public number = '',
		public zipCode: ZipCode = new ZipCode(),
		public complement = '',
	) {}
}
