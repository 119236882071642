import User from "./user";

export default class ProposalHistory {
    constructor(
        public readonly id: string,
        public readonly description: string,
        public readonly isMain: boolean,
        public readonly user: User,
        public readonly createdAt: number
    ) {}
}
