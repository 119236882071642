import LoanQuiz from "../entities/valueObjects/loanQuiz";

export default class LoanQuizDTO {
    static fromJson(json: any): LoanQuiz {
        return new LoanQuiz(
            json.isOwned,
            json.ThirdPartyBondDegree,
            json.isThirdParty,
            json.isPublicServant,
            json.registrationNumber,
            json.averbId,
            json.sphereOfAction,
            json.agency
        )
    }
}