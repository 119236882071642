import { ScheduleVisits } from "../entities/scheduleVisits";
import ScheduleVisitDTO from "./scheduleVisitDTO";


export default class ScheduleVisitsDTO {
    static fromJson(json: any): ScheduleVisits {
        return {
            scheduleVisits: json.data.map((scheduleVisit: any) =>  ScheduleVisitDTO.fromJson(scheduleVisit)),
            totalItems: json.totalItems
        }
    }
}