export default class ProposalStatus {
    public readonly PENDING_TERMS_SUBMISSION = 'pending_terms_submission';
    public readonly PENDING_TERMS_SIGNATURE = 'pending_terms_signature';
    public readonly REJECTED_TERMS = 'rejected_terms';
    public readonly ANALYZING = 'analyzing';
    public readonly REJECTED = 'rejected';
    public readonly AVAILABLE_PROPOSALS = 'available_proposals';
    public readonly PROPOSAL_SENT_TO_THE_CUSTOMER = 'proposal_sent_to_the_customer';
    public readonly PROPOSAL_ACCEPTED_BY_THE_CUSTOMER = 'proposal_accepted_by_the_customer';
    public readonly PROPOSAL_REJECTED_BY_THE_CUSTOMER = 'proposal_rejected_by_the_customer';
    public readonly COMPLETED = 'completed';

    public readonly ALL_STATUS = [
        this.PENDING_TERMS_SUBMISSION,
        this.PENDING_TERMS_SIGNATURE,
        this.REJECTED_TERMS,
        this.ANALYZING,
        this.REJECTED,
        this.AVAILABLE_PROPOSALS,
        this.PROPOSAL_SENT_TO_THE_CUSTOMER,
        this.PROPOSAL_ACCEPTED_BY_THE_CUSTOMER,
        this.PROPOSAL_REJECTED_BY_THE_CUSTOMER,
        this.COMPLETED,
    ];

    public proposalStatusMap: Record<string, string> = {
        pending_terms_submission: 'Envio de termos pendente',
        pending_terms_signature: 'Assinatura de termos pendente',
        rejected_terms: 'Termos rejeitados',
        analyzing: 'Analisando',
        rejected: 'Rejeitado',
        available_proposals: 'Propostas disponíveis',
        proposal_sent_to_the_customer: 'Proposta enviada ao cliente',
        proposal_accepted_by_the_customer: 'Proposta aceita pelo cliente',
        proposal_rejected_by_the_customer: 'Proposta rejeitada pelo cliente',
        completed: 'Concluído',
        unknown: 'Desconhecido'
    };

    constructor(private proposalStatus: string) {
        this.proposalStatus = this.ALL_STATUS.includes(proposalStatus) ? proposalStatus : 'unknown';
    }

    public getStatus(): string {
        return this.proposalStatus;
    }

    public getFormatted(): string {
        return this.proposalStatusMap[this.proposalStatus];
    }

    static getAllStatus(): string[] {
        return new ProposalStatus('').ALL_STATUS;
    }
}