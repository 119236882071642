import { Spreadsheets } from "../entities/spreadsheets";
import SpreadsheetDTO from "./spreadsheetDTO";


export default class SpreadsheetsDTO {
    static fromJson(json: any): Spreadsheets {
        return {
            spreadsheets: json.data.map((spreadsheet: any) =>  SpreadsheetDTO.fromJson(spreadsheet)),
            totalItems: json.totalItems
        }
    }
}