import { Proposals } from "../entities/proposals";
import ProposalDTO from "./proposalDTO";


export default class ProposalsDTO {
    static fromJson(json: any): Proposals {
        return {
            proposals: json.data.map((proposal: any) =>  ProposalDTO.fromJson(proposal)),
            totalItems: json.totalItems
        }
    }
}