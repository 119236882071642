import React from 'react';
import { CircleX } from 'lucide-react';
import '../../styles/components/_popuperror.scss';

const PopupError: React.FC<{ message?: string; onClose: () => void }> = ({ message, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="error-icon">
          <CircleX size={70} />
        </div>
        <h2 className="titulo-popup">Ops! Algo deu errado!</h2>
        <p className="frase-popup">{message || 'Algum erro aqui'}</p>
        <button onClick={onClose} className="btn-close-popup">
          Fechar
        </button>
      </div>
    </div>
  );
};

export default PopupError;
