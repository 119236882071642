import { Formatter } from "../../../utils/formatter"

export default class ZipCode {
  private readonly value: string;

  constructor(code: string = '') {
    this.value = code;
  }

  public validate(): boolean {
    return /^\d{5}-\d{3}$/.test(this.value) || /^\d{8}$/.test(this.value) || /^\d{2}.\d{3}-\d{3}$/.test(this.value)
  }

  public getCodeFormatted(): string {
    if (this.value.length === 8) {
      return Formatter.strToCep(this.value)
    }
    return this.value
  }

  public getCodeWithoutSymbols(): string {
    return Formatter.clearSymbolsAndLetters(this.value)
  }

  public toString(): string {
    return this.value;
  }

  public toJSON(): string {
    return this.value;
  }
}