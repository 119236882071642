import { Leads } from "../entities/leads";
import LeadDTO from "./leadDTO";


export default class LeadsDTO {
    static fromJson(json: any): Leads {
        return {
            leads: json.data.map((lead: any) =>  LeadDTO.fromJson(lead)),
            totalItems: json.totalItems
        }
    }
}