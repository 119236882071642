import UploadImageRepository from "../repository/uploadImageRepository"

export default class UploadImageProvider {
    private static instance: UploadImageProvider
    private UploadImageRepository: UploadImageRepository

    private constructor() {
        this.UploadImageRepository = UploadImageRepository.getInstance()
    }

    static getInstance(): UploadImageProvider {
        if (!UploadImageProvider.instance) {
            UploadImageProvider.instance = new UploadImageProvider()
        }
        return UploadImageProvider.instance
    }

    async upload(file: File): Promise<string> {
        return await this.UploadImageRepository.upload(file)
    }
}