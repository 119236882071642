import React from 'react';
import '../styles/components/_loading.scss';

const Loading: React.FC = () => {
  return (
    <div className="loading-overlay">
      <div className="spinner-components"></div>
      <p className="loading-text-components">Carregando...</p>
    </div>
  );
};

export default Loading;
