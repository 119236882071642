import { PreApprovedType } from "../entities/valueObjects/preApprovedType";
import PreApprovedValue from "../entities/valueObjects/preApprovedValue";

export default class PreApprovedValueDTO {
    static fromJson(json: any): PreApprovedValue {
        return new PreApprovedValue(
            json.id,
            json.amount,
            new PreApprovedType(json.type),
            json.createdAt
        )
    }

    static toJson(preApprovedValue: PreApprovedValue): any {
        return {
            id: preApprovedValue.id,
            amount: preApprovedValue.amount,
            type: preApprovedValue.type.getType(),
            createdAt: preApprovedValue.createdAt
        }
    }
}