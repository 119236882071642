import VehicleQuiz from "../entities/valueObjects/vehicleQuiz";

export default class VehicleQuizDTO {
    static fromJson(json: any): VehicleQuiz {
        return new VehicleQuiz(
            json.value,
            json.isOwned,
            json.isThirdParty,
            json.isFinanced,
            json.wantsRefinance,
            json.refinanceValue,
            json.useAsPayment,
            json.vehicleType,
            json.year,
            json.model,
            json.manufacturer,
            json.km
        )
    }
}