import Profile from "../../domain/entities/profile"
import { AxiosHttp } from "../../http/axios"
import UserRepository from "../repository/userRepository"

export default class UserProvider {
    private static instance: UserProvider
    private userRepository: UserRepository
    authErrors: { email: string, password: string } = { email: '', password: '' }

    private constructor() {
        this.userRepository = UserRepository.getInstance(AxiosHttp.getInstance())
    }

    static getInstance(): UserProvider {
        if (!UserProvider.instance) {
            UserProvider.instance = new UserProvider()
        }
        return UserProvider.instance
    }

    async getUserByDocument(document: string): Promise<void | Profile> {
        return await this.userRepository.getUserByDocument(document)
    }
}