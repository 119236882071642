import LeadDTO from "../../domain/dto/leadDTO";
import LeadsDTO from "../../domain/dto/leadsDTO";
import ListSearchDTO from "../../domain/dto/listSearchDTO";
import Lead from "../../domain/entities/lead";
import { Leads } from "../../domain/entities/leads";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class LeadRepository {
    private static instance: LeadRepository | null = null

    constructor(
        private http: Http
    ) {}

    static getInstance(http: Http): LeadRepository {
        if (!LeadRepository.instance) {
            LeadRepository.instance = new LeadRepository(http)
        }
        return LeadRepository.instance
    }

    async createLead(lead: Lead): Promise<Lead> {
        const request = new HttpRequest(
            ApiRoutes.lead,
            lead
        );

        return await this.http.post(request);
    }

    async getLeadByDocument(document: string): Promise<Lead> {
        const request = new HttpRequest(
            ApiRoutes.lead,
            undefined,
            { document }
        );

        const response = await this.http.get(request);
        return LeadDTO.fromJson(response);
    }

    async getLeads(listSearch: ListSearch): Promise<Leads> {
        const request = new HttpRequest(
            ApiRoutes.lead,
            undefined,
            ListSearchDTO.toJson(listSearch)
        );

        const response = await this.http.get<Leads>(request);
        return LeadsDTO.fromJson(response);
    }

    async getLeadById(id: string): Promise<Lead> {
        const request = new HttpRequest(
            `${ApiRoutes.lead}/${id}`,
        );

        const response = await this.http.get<Lead>(request);
        return LeadDTO.fromJson(response);
    }

    async createUserFromLead(lead: Lead): Promise<Lead> {
        const request = new HttpRequest(
            ApiRoutes.leadCreateUser.replace('{id}', lead.id),
            lead
        );

        return await this.http.post(request);
    }
}