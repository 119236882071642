import Spreadsheet from "../../domain/entities/spreadsheet"
import { Spreadsheets } from "../../domain/entities/spreadsheets"
import { ListSearch } from "../../domain/entities/valueObjects/listSearch"
import { AxiosHttp } from "../../http/axios"
import SpreadsheetRepository from "../repository/spreadsheetRepository"

export default class SpreadsheetProvider {
    private static instance: SpreadsheetProvider
    private spreadsheetRepository: SpreadsheetRepository
    authErrors: { email: string, password: string } = { email: '', password: '' }

    private constructor() {
        this.spreadsheetRepository = SpreadsheetRepository.getInstance(AxiosHttp.getInstance())
    }

    static getInstance(): SpreadsheetProvider {
        if (!SpreadsheetProvider.instance) {
            SpreadsheetProvider.instance = new SpreadsheetProvider()
        }
        return SpreadsheetProvider.instance
    }

    async getSpreadsheets(listSearch: ListSearch): Promise<Spreadsheets> {
        return await this.spreadsheetRepository.getSpreadshets(listSearch)
    }

    async getSpreadsheetById(spreadsheetId: number): Promise<Spreadsheet>{
        return await this.spreadsheetRepository.getSpreadshetById(spreadsheetId)
    }

    async deleteSpreadsheet(spreadsheetId: number): Promise<void> {
        await this.spreadsheetRepository.deleteSpreadsheet(spreadsheetId)
    }

    async updateSpreadsheet(spreadsheet: Spreadsheet): Promise<Spreadsheet> {
        return await this.spreadsheetRepository.updateSpreadsheet(spreadsheet)
    }

    async createSpreadsheet(method: string, description: string, path: string | undefined): Promise<Spreadsheet> {
        return await this.spreadsheetRepository.createSpreadsheet(method, description, path)
    }
}