import ListSearchDTO from "../../domain/dto/listSearchDTO";
import SpreadsheetDTO from "../../domain/dto/spreadsheetDTO";
import SpreadsheetsDTO from "../../domain/dto/spreadsheetsDTO";
import Spreadsheet from "../../domain/entities/spreadsheet";
import { Spreadsheets } from "../../domain/entities/spreadsheets";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class SpreadsheetRepository {
    private static instance: SpreadsheetRepository | null = null

    constructor(
        private http: Http
    ) {}

    static getInstance(http: Http): SpreadsheetRepository {
        if (!SpreadsheetRepository.instance) {
            SpreadsheetRepository.instance = new SpreadsheetRepository(http)
        }
        return SpreadsheetRepository.instance
    }

    async getSpreadshets(listSearch: ListSearch): Promise<Spreadsheets> {
        const request = new HttpRequest(
            ApiRoutes.spreadsheet,
            undefined,
            ListSearchDTO.toJson(listSearch)
        );

        const response = await this.http.get<Spreadsheets>(request);
        return SpreadsheetsDTO.fromJson(response);
    }

    async getSpreadshetById(spreadsheetId: number): Promise<Spreadsheet>{
        const request = new HttpRequest(
            `${ApiRoutes.spreadsheet}/${spreadsheetId}`,
        );

        const response = await this.http.get<Spreadsheet>(request);
        return SpreadsheetDTO.fromJson(response);
    }

    async deleteSpreadsheet(spreadsheetId: number): Promise<void> {
        const request = new HttpRequest(
            `${ApiRoutes.spreadsheet}/${spreadsheetId}`,
        );

        await this.http.delete(request);
    }

    async updateSpreadsheet(spreadsheet: Spreadsheet): Promise<Spreadsheet> {
        const request = new HttpRequest(
            `${ApiRoutes.spreadsheet}/${spreadsheet.id}`,
            SpreadsheetDTO.toJson(spreadsheet)
        );

        const response = await this.http.put<Spreadsheet>(request);
        return SpreadsheetDTO.fromJson(response);
    }

    async createSpreadsheet(method: string, description: string, path: string | undefined): Promise<Spreadsheet> {
        const request = new HttpRequest(
            ApiRoutes.spreadsheet,
            { method, description, documentLink: path }
        );

        const response = await this.http.post<Spreadsheet>(request);
        return SpreadsheetDTO.fromJson(response);
    }
}