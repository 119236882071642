import React, { useState, useEffect } from "react";
import { Outlet } from "react-router";
import DrawerFilter from "../components/DrawerFilter";
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar";
import ReleaseTypeProvider from "../infra/provider/releaseTypeProvider";

const MainLayout: React.FC<{
  headerTitle: string;
  setHeaderTitle: (title: string) => void;
}> = ({ headerTitle, setHeaderTitle }) => {
  const isAuthPage =
    window.location.pathname === "/login" ||
    window.location.pathname === "/esqueci-minha-senha";
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchReleaseTypes = async () => {
      ReleaseTypeProvider.getInstance();
    };
    fetchReleaseTypes();
  }, []);

  return (
    <div className={`app-container ${isAuthPage ? "auth-page" : ""}`}>
      {!isAuthPage && <Sidebar setHeaderTitle={setHeaderTitle} />}
      <div className="main-content">
        {!isAuthPage && <Header title={headerTitle} />}
        <Outlet /> {/* Define onde os elementos de rota serão renderizados */}
      </div>
      {!isAuthPage && (
        <DrawerFilter
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}
    </div>
  );
};

export default MainLayout;
