import Step from "./step";
import ConstructionStatus from "./valueObjects/constructionStatus";

export default class ConstructionStep {
    constructor(
        public id: string = '',
        public name: string = '',
        public description: string = '',
        public estimationDuration: number = 0,
        public durationType: string = '',
        public status: ConstructionStatus = new ConstructionStatus('unknown'),
        public steps: Step[] = []
    ) {}
}
