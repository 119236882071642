// src/components/Tooltip.tsx
import React, { ReactNode } from 'react';
import '../../styles/components/_tooltip.scss';

interface TooltipProps {
  text: string;
  children: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  return (
    <div className="tooltip-container">
      {children}
      <div className="tooltip">{text}</div>
    </div>
  );
};

export default Tooltip;
