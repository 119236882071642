import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/components/_listapropostas.scss";
import ProposalProvider from "../../infra/provider/proposalProvider";
import { Formatter } from "../../utils/formatter";
import { debounce } from "lodash";
import ProposalStatus from "../../domain/entities/valueObjects/proposalStatus";
import { toast } from "react-toastify";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";

interface TableData {
  id: string;
  createdAt: string;
  name: string;
  document: string;
  phone: string;
  email: string;
  enterpriseName: string;
  value: string;
  status: string;
}

const Enterprises = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [data, setData] = useState<TableData[]>([]);
  const navigate = useNavigate();
  const isFetching = useRef(false);

  const proposalProvider = ProposalProvider.getInstance();

  const getProposals = useCallback(
    async (pageNumber = 1, searchTerm = "", selectedDate = "") => {
      if (isFetching.current) return;
      isFetching.current = true;
      const search = new ListSearch({
        searchTerm: searchTerm,
        status: selectedStatus,
        orderBy: "createdAt",
        limit: itemsPerPage,
        offset: (pageNumber - 1) * itemsPerPage,
        fromDate: selectedDate ? Formatter.dateStringToTimestamp(selectedDate) : undefined,
        toDate: selectedDate ? Formatter.dateStringToTimestamp(selectedDate) : undefined,
      });
      try {
        const response = await proposalProvider.getProposals(search);
        const mappedData = response.proposals.map((proposal) => ({
          id: proposal.id,
          createdAt: Formatter.timestampToDateBR(proposal.createdAt),
          name: proposal.lead.name,
          document: proposal.lead.document,
          phone: proposal.lead.phone,
          email: proposal.lead.email,
          enterpriseName: proposal.enterprise.name,
          value: proposal.unitPrice.getValueFormatted(),
          status: proposal.status.getFormatted(),
        }));
        setData(mappedData);
        setTotalItems(response.totalItems);
      } catch (error) {
        console.error(error);
        toast.error("Erro ao buscar propostas");
      } finally {
        isFetching.current = false;
      }
    },
    [proposalProvider, itemsPerPage, selectedStatus]
  );

  useEffect(() => {
    getProposals(currentPage);
  }, [itemsPerPage, currentPage, getProposals]);

  const handleRowClick = (id: string) => {
    navigate(`/propostas/${id}`);
  };

  const debouncedSearchTerm = useRef(
    debounce((term: any) => {
      getProposals(1, term, selectedDate);
    }, 600)
  ).current;

  const debouncedDate = useRef(
    debounce((date: any) => {
      getProposals(1, searchTerm, date);
    }, 600)
  ).current;

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    debouncedSearchTerm(searchTerm);
  };

  const handleSearchDateChange = (date: string) => {
    setSelectedDate(date);
    debouncedDate(date);
  };

  // Função para limpar todos os filtros
  const handleClearFilters = () => {
    if (searchTerm === "" && selectedStatus === "" && selectedDate === "") return;
    setSearchTerm("");
    setSelectedStatus("");
    setSelectedDate("");
    setCurrentPage(1); // Opcional, se quiser resetar para a primeira página
    getProposals(1, "", "");
  };

  // Função para formatar CPF
  const formatCPF = (document: string) => {
    return document.replace(/\D/g, "") // Remove qualquer coisa que não seja número
      .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4"); // Aplica a máscara
  };

  // Função para formatar phone
  const formatphone = (phone: string): string => {
    const cleaned = phone.replace(/\D/g, "");
    if (cleaned.length === 13) {
      return `+${cleaned.slice(0, 2)} (${cleaned.slice(2, 4)}) ${cleaned.slice(4, 8)}-${cleaned.slice(8)}`;
    } else if (cleaned.length === 11) {
      return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
    }
    return phone;
  };



  return (
    <div className="data-table-container">
      <div className="filters-container">
        <div className="search-container">
          <input
            type="text"
            placeholder="Busque por um nome, CPF, ID etc..."
            value={searchTerm}
            onChange={(e) => handleSearchTermChange(e.target.value)}
          />
          <button className="search-button"></button>
        </div>
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          className="status-select"
        >
          <option value="">Selecione um status</option>
          {ProposalStatus.getAllStatus().map((status) => (
            <option key={status} value={status}>
              {new ProposalStatus(status).getFormatted()}
            </option>
          ))}
        </select>
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => handleSearchDateChange(e.target.value)}
          className="date-input"
        />
        {/* Botão para limpar filtros */}
        <button onClick={handleClearFilters} className="clear-filters-button-enterprise">
          Limpar Filtros
        </button>
      </div>

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Data de criação</th>
              <th>Nome</th>
              <th>CPF</th>
              <th>Celular</th>
              <th>Email</th>
              <th>Empreedimento</th>
              <th>Valor</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} onClick={() => handleRowClick(item.id)}>
                <td>{item.id}</td>
                <td>{item.createdAt}</td>
                <td>{item.name}</td>
                <td> {item.document && formatCPF(item.document)}</td>
                <td>{item.phone && formatphone(item.phone)}</td>
                <td>{item.email}</td>
                <td>{item.enterpriseName}</td>
                <td>{item.value}</td>
                <td>{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="table-footer">
        <div className="items-per-page">
          <span>Exibir por</span>
          <select
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <div className="pagination">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={currentPage === page ? "active" : ""}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            Próximo
          </button>
        </div>
      </div>
    </div>
  );
};

export default Enterprises;
