import ConstructionCompany from "../entities/constructionCompany";

export default class ConstructionCompanyDTO {
    public static fromJson(json: any): ConstructionCompany {
        return new ConstructionCompany(
            json.id,
            json.name,
            json.fantasyName,
            json.cnpj,
            json.address,
            json.city,
            json.state,
            json.phone,
            json.email,
            json.responsible,
            json.responsiblePhone,
            json.responsibleEmail,
            json.license
        );
    }
}