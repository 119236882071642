import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/components/_login.scss';
import { FaExclamationCircle, FaEye, FaEyeSlash } from 'react-icons/fa';
import AuthProvider from '../../infra/provider/authProvider';
import header from '../../assets/images/header.png';
import logo from '../../assets/images/homecorp-logo.png';
import PopupError from '../popup/PopupError';

const Login = () => {
  const authProvider = AuthProvider.getInstance();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [remember, setRemember] = useState(false)
  const [popup, setPopup] = useState(false)
  const [error, setError] = useState('')
  const [authErrors, setAuthErrors] = useState({ email: '', password: '' })
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    if (userEmail) setEmail(userEmail);
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const errors = await authProvider.signin(email, password);
      if (errors) {
        setAuthErrors(errors);
        setIsLoading(false);

        return;
      }
      if (remember) localStorage.setItem('userEmail', email);
      navigate('/home');
    } catch (error: any) {
      setError('Não foi possível realizar o login. Verifique se o e-mail e a senha estão corretos e tente novamente.');
      setPopup(true);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="login-container-login">
      <div className="login-image">
        <img src={header} alt="Construtech" />
      </div>
      <div className="login-form-login">
        <div className="form-container-login">
          <img src={logo} alt="Homecorp logo" className="logo-login" />
          <h1>Bem-vindo à Plataforma Homecorp!</h1>
          <p className='frase-form-login'>Para fazer login preencha os dados abaixo.</p>
          <form onSubmit={handleSubmit}>
            <label>E-mail</label>
            <input
              type="email"
              placeholder="Digite o seu e-mail"
              value={email}
              onChange={(email) => setEmail(email.target.value)}
              className={authErrors.email ? 'error' : ''}
            />
            {authErrors.email && (
              <div className="error-message">
                <span className="icon-alert">
                  <FaExclamationCircle />
                </span>
                {authErrors.email}
              </div>
            )}

            <label>Senha</label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Digite a sua senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={authErrors.password ? 'error' : ''}
              />
              <span
                className="show-password-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {authErrors.password && (
              <div className="error-message">
                <span className="icon-alert">
                  <FaExclamationCircle />
                </span>
                {authErrors.password}
              </div>
            )}

            <div className="remember-me">
              <input type="checkbox" checked={remember} onChange={() => setRemember(!remember)} id="remember" className='input-remember' />
              <label htmlFor="remember">Lembrar-se de mim</label>
            </div>

            <button type="submit" disabled={isLoading}>
              {isLoading ? (
                <span className="spinner">
                  Acessando...
                </span>
              ) : (
                "Acessar conta"
              )}
            </button>
          </form>
          <a href="/esqueci-minha-senha">Esqueci minha senha</a>
        </div>
      </div>
      {popup && (PopupError({
        message: error,
        onClose: () => setPopup(false)
      }))
      }

    </div>
  );
};

export default Login;
