import Profile from "../entities/profile";
import AddressDTO from "./addressDTO";
import UserDTO from "./userDTO";

export default class ProfileDTO {
    static fromJson(json: any): Profile {
        return new Profile(
            json.id,
            json.name,
            json.phone,
            json.document,
            AddressDTO.fromJson(json.address),
            UserDTO.fromJson(json.user),
            json.image
        )
    }

    static toJson(profile: Profile): any {
        return {
            id: profile.id,
            password: profile.user.password,
            email: profile.user.email,
            name: profile.name,
            phone: profile.phone,
            document: profile.document,
            address: profile.address,
            user: profile.user,
            image: profile.image
        }
    }
}