import ProfileDTO from "../../domain/dto/profileDTO";
import Profile from "../../domain/entities/profile";
import User from "../../domain/entities/user";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class UserRepository {
    private static instance: UserRepository | null = null

    constructor(
        private http: Http
    ) {}

    static getInstance(http: Http): UserRepository {
        if (!UserRepository.instance) {
            UserRepository.instance = new UserRepository(http)
        }
        return UserRepository.instance
    }

    async getUserByDocument(document: string): Promise<Profile> {
        const request = new HttpRequest(
            ApiRoutes.user,
            undefined,
            { document }
        );

        const response = await this.http.get<User>(request);
        return ProfileDTO.fromJson(response);
    }
}