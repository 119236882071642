import Lead from "../../domain/entities/lead"
import { Leads } from "../../domain/entities/leads"
import { ListSearch } from "../../domain/entities/valueObjects/listSearch"
import { AxiosHttp } from "../../http/axios"
import LeadRepository from "../repository/leadRepository"

export default class LeadProvider {
    private static instance: LeadProvider
    private leadRepository: LeadRepository
    authErrors: { email: string, password: string } = { email: '', password: '' }

    private constructor() {
        this.leadRepository = LeadRepository.getInstance(AxiosHttp.getInstance())
    }

    static getInstance(): LeadProvider {
        if (!LeadProvider.instance) {
            LeadProvider.instance = new LeadProvider()
        }
        return LeadProvider.instance
    }

    async createLead(lead: Lead): Promise<void | Lead> {
        return await this.leadRepository.createLead(lead)
    }

    async getLeadByDocument(document: string): Promise<Lead> {
        return await this.leadRepository.getLeadByDocument(document)
    }

    async getLeads(listSearch: ListSearch): Promise<Leads> {
        return await this.leadRepository.getLeads(listSearch)
    }

    async getLeadById(id: string) {
        return await this.leadRepository.getLeadById(id)
    }

    async createUserFromLead(lead: Lead) {
        return await this.leadRepository.createUserFromLead(lead)
    }
}