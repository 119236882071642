import { AxiosHttp } from "../../http/axios";
import ReleaseType from "../../domain/entities/valueObjects/releaseType";
import ReleaseTypeRepository from "../repository/releaseTypeRepository";

export default class ReleaseTypeProvider {
    private static instance: ReleaseTypeProvider;
    private releaseTypeRepository: ReleaseTypeRepository;
    private releaseTypes: ReleaseType[] = [];

    private constructor() {
        this.releaseTypeRepository = ReleaseTypeRepository.getInstance(AxiosHttp.getInstance());
        this.loadReleaseTypes();
    }

    static getInstance(): ReleaseTypeProvider {
        if (!ReleaseTypeProvider.instance) {
            ReleaseTypeProvider.instance = new ReleaseTypeProvider();
        }
        return ReleaseTypeProvider.instance;
    }

    private async loadReleaseTypes() {
        this.releaseTypes = await this.releaseTypeRepository.getReleaseTypes();
    }

    getReleaseTypes(): ReleaseType[] {
        return this.releaseTypes;
    }

getReleaseTypeById(id: string | number): ReleaseType | undefined {
    return this.releaseTypes.find(releaseType => releaseType.id.toString() === id.toString());
}
}