export default class FgtsQuiz {
    constructor(
        public balance: number | undefined = undefined,
        public hasAnniversaryWithdrawal: boolean | undefined = undefined,
        public useAsPayment: boolean | undefined = undefined,
        public useForAmortization: boolean | undefined = undefined,
        public hasWithdrawalTwo: boolean | undefined = undefined,
        public balanceTwo: number | undefined = undefined,
        public hasAnniversaryWithdrawalTwo: boolean | undefined = undefined,
        public useAsPaymentTwo: boolean | undefined = undefined,
    ) {}
}