import ProjectPlan from "../entities/projectPlan";
import Room from "../entities/valueObjects/room";

export default class ProjectPlanDTO {
    public static fromJson(json: any): ProjectPlan {
        return new ProjectPlan(
            json.id,
            json.name,
            json.area,
            json.builtArea,
            json.rooms? json.rooms.map((room: any) => Room.fromJson(room)) : [],
        );
    }
}