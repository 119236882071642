
export default class Spreadsheet {
    constructor(
        public readonly id: number,
        public readonly description: string,
        public readonly createdAt: number,
        public readonly updatedAt: number,
        public readonly createdBy: string,
        public readonly updatedBy: string,
        public readonly path: string,
    ) {}
}
