import { Enterprises } from "../entities/enterprises";
import EnterpriseDTO from "./enterpriseDTO";


export default class EnterprisesDTO {
    static fromJson(json: any): Enterprises {
        return {
            enterprises: json.data.map((enterprise: any) =>  EnterpriseDTO.fromJson(enterprise)),
            totalItems: json.totalItems
        }
    }
}