import PropertyCategoryDTO from "../../domain/dto/propertyCategory";
import PropertyCategory from "../../domain/entities/valueObjects/propertyCategory";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class PropertyCategoryRepository {
    private static instance: PropertyCategoryRepository | null = null

    constructor(
        private http: Http
    ) {}

    static getInstance(http: Http): PropertyCategoryRepository {
        if (!PropertyCategoryRepository.instance) {
            PropertyCategoryRepository.instance = new PropertyCategoryRepository(http)
        }
        return PropertyCategoryRepository.instance
    }

    async createPropertyCategory(propertyCategory: PropertyCategory): Promise<PropertyCategory> {
        const request = new HttpRequest(
            ApiRoutes.propertyCategory,
            propertyCategory
        );

        return await this.http.post(request);
    }

    async getPropertyCategorys(): Promise<PropertyCategory[]> {
        const request = new HttpRequest(
            ApiRoutes.propertyCategory,
        );

        const response = await this.http.get<PropertyCategory[]>(request);
        return response.map((propertyCategory) => PropertyCategoryDTO.fromJson(propertyCategory));
    }

    async getPropertyCategoryById(id: string): Promise<PropertyCategory> {
        const request = new HttpRequest(
            `${ApiRoutes.propertyCategory}/${id}`,
        );

        const response = await this.http.get<PropertyCategory>(request);
        return PropertyCategoryDTO.fromJson(response);
    }
}