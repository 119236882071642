import Ownership from "./ownership";
import SignalMethod from "./signalMethod";

export default class PaymentSignal {
    constructor(
        public method: SignalMethod = new SignalMethod("unknown"),
        public amount: number = 0,
        public date: number = 0,
        public installments: number | undefined = undefined,
        public ownership: Ownership | undefined = undefined,
        public thirdPartyDocument: string | undefined = undefined,
    ) {}
}
