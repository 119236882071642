import ScheduleVisit from "../entities/scheduleVisit";
import UserDataDTO from "./userDataDTO";

export default class ScheduleVisitDTO {
    static fromJson(json: any): ScheduleVisit {
        return new ScheduleVisit(
            json.id,
            json.date,
            json.createdAt,
            json.obs,
            json.requester ? UserDataDTO.fromJson(json.requester) : undefined,
            json.responsible ? UserDataDTO.fromJson(json.responsible) : undefined,
            json.enterprise.name,
        )
    }
}