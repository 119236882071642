import ProposalDocument from "../entities/proposalDocument";

export default class ProposalDocumentDTO {
    static fromJson(json: any): ProposalDocument {
        return new ProposalDocument(
            json.id,
            json.createdAt,
            json.name,
            json.path
        )
    }

    static toJson(proposalDocument: ProposalDocument): any {
        return {
            name: proposalDocument.name,
            documentLink: proposalDocument.path
        }
    }
}