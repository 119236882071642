import { Address } from "../entities/address"
import ZipCode from "../entities/valueObjects/zipCode"

export default class AddressDTO {
    static fromJson (json: any): Address {
		return new Address(
			json.id,
			json.city,
			json.uf,
			json.neighborhood,
			json.street,
			json.number,
			new ZipCode(json.zipCode),
			json.complement,
		)
	}

	public toJson (address: Address): any {
		return {
			id: address.id,
			city: address.city,
			uf: address.uf,
			neighborhood: address.neighborhood,
			street: address.street,
			number: address.number,
			zipCode: address.zipCode.getCodeWithoutSymbols(),
			complement: address.complement,
		}
	}
}