import ProposalHistory from "../entities/proposalHistory";
import UserDTO from "./userDTO";

export default class ProposalHistoryDTO {
    static fromJson(json: any): ProposalHistory {
        return new ProposalHistory(
            json.id,
            json.description,
            json.isMain,
            UserDTO.fromJson(json.user),
            json.createdAt
        )
    }
}