import ReleaseTypeDTO from "../../domain/dto/releaseTypeDTO";
import ReleaseType from "../../domain/entities/valueObjects/releaseType";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class ReleaseTypeRepository {
    private static instance: ReleaseTypeRepository | null = null

    constructor(
        private http: Http
    ) {}

    static getInstance(http: Http): ReleaseTypeRepository {
        if (!ReleaseTypeRepository.instance) {
            ReleaseTypeRepository.instance = new ReleaseTypeRepository(http)
        }
        return ReleaseTypeRepository.instance
    }

    async createReleaseType(releaseType: ReleaseType): Promise<ReleaseType> {
        const request = new HttpRequest(
            ApiRoutes.releaseType,
            releaseType
        );

        return await this.http.post(request);
    }

    async getReleaseTypes(): Promise<ReleaseType[]> {
        const request = new HttpRequest(
            ApiRoutes.releaseType,
        );

        const response = await this.http.get<ReleaseType[]>(request);
        return response.map((releaseType) => ReleaseTypeDTO.fromJson(releaseType));
    }

    async getReleaseTypeById(id: string): Promise<ReleaseType> {
        const request = new HttpRequest(
            `${ApiRoutes.releaseType}/${id}`,
        );

        const response = await this.http.get<ReleaseType>(request);
        return ReleaseTypeDTO.fromJson(response);
    }
}