import { Address } from "./address";

export default class CreateEnterprise {
    constructor(
        public name = '',
        public description = '',
        public categoryId = '',
        public releaseTypeId = '',
        public constructionCompanyId = '',
        public deliveryEstimation = 0,
        public tags: string[] = [],
        public services: string[]  = [],
        public commonAreas: string[]  = [],
        public spaces: string[]  = [],
        public address = new Address(),
        public imagesUrl: string[]  = [],
    ) {}
}