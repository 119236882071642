import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'; 
import DrawerFilter from '../DrawerFilter'; 
import { useLocation, Link } from 'react-router-dom';
import '../../styles/components/_header.scss';

const Header: React.FC<{ title: string }> = ({ title }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const generateBreadcrumb = () => {
    const pathParts = location.pathname.split('/').filter(part => part); 
    const breadcrumbParts = [];

    breadcrumbParts.push(
      <span key="home">
        <Link to="/">HomeCorp</Link>
      </span>
    );

    pathParts.forEach((part, index) => {
      const path = `/${pathParts.slice(0, index + 1).join('/')}`;
      const label = part.charAt(0).toUpperCase() + part.slice(1); 
      breadcrumbParts.push(
        <React.Fragment key={path}>
          <FontAwesomeIcon icon={faChevronRight} className="icon-arrow" />
          <Link to={path}>{label}</Link>
        </React.Fragment>
      );
    });

    return breadcrumbParts;
  };

  return (
    <>
      <header className="header-main">
        <div className="header-content-main">
          <div className="breadcrumb">
            {generateBreadcrumb()}
          </div>
          <h2 className='title-header-main'>Visualize sua lista de: {title.toLowerCase()}</h2>
        </div>
      </header>

      <DrawerFilter isOpen={isDrawerOpen} onClose={toggleDrawer} />
    </>
  );
};

export default Header;