import ConstructionCompany from "../entities/constructionCompany";
import Enterprise from "../entities/enterprise";
import EnterpriseStatus from "../entities/valueObjects/enterpriseStatus";
import ReleaseType from "../entities/valueObjects/releaseType";
import AddressDTO from "./addressDTO";
import ConstructionCompanyDTO from "./constructionCompanyDTO";
import ConstructionStepDTO from "./constructionStepDTO";
import ProjectPlanDTO from "./projectPlanDTO";
import PropertyCategoryDTO from "./propertyCategory";
import ReleaseTypeDTO from "./releaseTypeDTO";
import UnitDTO from "./unitDTO";

export default class EnterpriseDTO {
    static fromJson(json: any): Enterprise {
        return new Enterprise(
            json.id,
            json.name,
            json.description,
            PropertyCategoryDTO.fromJson(json.category),
            json.releaseType? ReleaseTypeDTO.fromJson(json.releaseType) : new ReleaseType(),
            json.constructionCompany ? ConstructionCompanyDTO.fromJson(json.constructionCompany) : new ConstructionCompany(),
            json.createdAt,
            json.deliveryEstimation,
            json.tags,
            json.services,
            json.commonAreas,
            AddressDTO.fromJson(json.address),
            new EnterpriseStatus(json.status),
            json.units ? json.units.map((unit: any) => UnitDTO.fromJson(unit)) : [],
            json.projectPlans ? json.projectPlans.map((projectPlan: any) => ProjectPlanDTO.fromJson(projectPlan)) : [],
            json.images,
            json.constructionSteps ? json.constructionSteps.map((step: any) => ConstructionStepDTO.fromJson(step)) : []

        )
    }
}