export default class Room {
    constructor(
        public id: string | undefined = undefined,
        public name: string | undefined = undefined,
        public quantity: number = 0,
        public obs: string | undefined = undefined,
    ) {}

    public static fromJson(json: any): Room {
        return new Room(
            json.id,
            json.name,
            json.quantity,
            json.obs
        );
    }
}