import ReleaseType from "../entities/valueObjects/releaseType"

export default class ReleaseTypeDTO {
    static fromJson(json: any): ReleaseType {
        return new ReleaseType(
            json.id,
            json.name,
            json.description
        )
    }

    static toJson(releaseType: any): any {
        return {
            id: releaseType.id,
            name: releaseType.name,
            description: releaseType.description,
        }
    }
}