export default class SignalMethod {
    public readonly PIX = 'pix';
    public readonly BOLETO = 'boleto';
    public readonly TRANSFER = 'transfer';
    public readonly CREDIT_CARD = 'creditCard';
    public readonly DEBIT_CARD = 'debitCard';

    public readonly ALL = [
        this.PIX,
        this.BOLETO,
        this.TRANSFER,
        this.CREDIT_CARD,
        this.DEBIT_CARD
    ];

    public signalMethodMap: Record<string, string> = {
        'pix': 'PIX',
        'boleto': 'Boleto',
        'transfer': 'Transferência',
        'creditCard': 'Cartão de Crédito',
        'debitCard': 'Cartão de Débito',
        'unknown': 'Desconhecido'
    };

    constructor(private signalMethod: string) {
        this.signalMethod = this.ALL.includes(signalMethod) ? signalMethod : 'unknown';
    }

    public getMethod(): string {
        return this.signalMethod;
    }

    public getFormatted(): string {
        return this.signalMethodMap[this.signalMethod];
    }
}

