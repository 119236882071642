import '../styles/components/_paginanaoencontrada.scss';
import Logo from '../assets/images/homecorp-logo.png';
import React from 'react';

export const PageNotFound = () => {
    return (
        <div className="paginanaoencontrada-container">
            <img src={Logo} alt="Logo" className='logo' />
            <h1 className='bg-primary'>Página não encontrada!</h1>
            <p>Desculpe, mas não encontramos essa página.</p>
        </div>
    );
}