import ProfileDTO from "../../domain/dto/profileDTO";
import Profile from "../../domain/entities/profile";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";
const API_KEY = process.env.REACT_APP_API_KEY

export default class AuthRepository {
    private static instance: AuthRepository | null = null

    constructor(
        private http: Http
    ) {}

    static getInstance(http: Http): AuthRepository {
        if (!AuthRepository.instance) {
            AuthRepository.instance = new AuthRepository(http)
        }
        return AuthRepository.instance
    }

    async signin(username: string, password: string) {
        const request = new HttpRequest(
            ApiRoutes.signin,
            undefined,
            undefined,
            {
                username: username,
                password: password
            },
            { 'Api-Key': API_KEY },
        );

        return await this.http.post<{accessToken: any, refreshToken: string}>(request);
    }

    async signup(profile: Profile) {
        new HttpRequest(
            ApiRoutes.signup,
            ProfileDTO.toJson(profile),
            undefined,
            undefined,
            { 'Api-Key': API_KEY },
        );
    }

    async refreshToken() {
        return await this.http.refreshToken();
    }

    async forgotPassword(email: string) {
        const request = new HttpRequest(
            ApiRoutes.forgotPassword,
            { email },
            undefined,
            undefined,
            { 'Api-Key': API_KEY },
        );
        
        return this.http.post(request);
    }

    async resetPassword(email: string, password: string, code: string) {
        const request = new HttpRequest(
            ApiRoutes.resetPassword,
            { email, password, code },
            undefined,
            undefined,
            { 'Api-Key': API_KEY },
        );
        
        return this.http.post(request);
    }

    async changePassword(oldPassword: string, newPassword: string) {
        const request = new HttpRequest(
            ApiRoutes.changePassword,
            {
                oldPassword,
                newPassword
            },
        );
        
        return this.http.post(request);
    }
}