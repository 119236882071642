import ConstructionStep from "../entities/constructionStep";
import ConstructionStatus from "../entities/valueObjects/constructionStatus";

export default class ConstructionStepDTO {
    public static fromJson(json: any): ConstructionStep {
        return new ConstructionStep(
            json.id,
            json.name,
            json.description,
            json.estimationDuration,
            json.durationType,
            new ConstructionStatus(json.status),
            json.steps
        );
    }
}