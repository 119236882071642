import Profile from "../../domain/entities/profile"
import { AxiosHttp } from "../../http/axios"
import { Validator } from "../../utils/validator"
import AuthRepository from "../repository/authRepository"

export default class AuthProvider {
    private static instance: AuthProvider
    private authRepository: AuthRepository
    authErrors: { email: string, password: string } = { email: '', password: '' }

    private constructor() {
        this.authRepository = AuthRepository.getInstance(AxiosHttp.getInstance())
    }

    static getInstance(): AuthProvider {
        if (!AuthProvider.instance) {
            AuthProvider.instance = new AuthProvider()
        }
        return AuthProvider.instance
    }

    async signin(username: string, password: string): Promise<void | {
        email: string,
        password: string
    }> {
        this.authErrors = { email: '', password: '' }
        if (!Validator.isValidEmail(username)) {
            this.authErrors.email = 'O email inserido está incorreto, verifique novamente.';
        }
        if (!Validator.isValidPassword(password)) {
            this.authErrors.password = 'A senha deve conter 8 caracteres, contendo pelo menos 1 maiúsculo, 1 especial e 1 número.';
        }

        if (this.authErrors.email || this.authErrors.password) return this.authErrors

        await this.authRepository.signin(username, password)
            .then((response) => {
                localStorage.setItem('accessToken', response.accessToken)
                localStorage.setItem('refreshToken', response.refreshToken)
            })
    }

    async signup(profile: Profile) {
        return this.authRepository.signup(profile)
    }

    async refreshToken() {
        return await this.authRepository.refreshToken()
    }

    async forgotPassword(email: string) {
        return await this.authRepository.forgotPassword(email)
    }

    async changePassword(password: string, newPassword: string) {
        return await this.authRepository.changePassword(password, newPassword)
    }
}