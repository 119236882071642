import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  ArrowUpRight,
  Download,
  Trash2,
  Edit2,
  X,
  ChevronLeft,
  ChevronRight,
  Upload,
  XCircle,
} from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/components/_planilhas.scss";
import Tooltip from "./tootip/Tootip";
import SpreadsheetProvider from "../infra/provider/spreadsheetProvider";
import { Formatter } from "../utils/formatter";
import { ListSearch } from "../domain/entities/valueObjects/listSearch";
import { debounce, set } from "lodash";
import UploadImageProvider from "../infra/provider/uploadImageProvider";
import Spreadsheet from "../domain/entities/spreadsheet";

interface TableData {
  id: number;
  createdAt: string;
  description: string;
  createdBy: string;
  path: string;
}

export function ViabilitySpreadsheet() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [data, setData] = useState<TableData[]>([]);
  const isFetching = useRef(false);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState<number | null>(
    null
  );
  const [uploadMode, setUploadMode] = useState<"base" | "upload">(
    "base"
  );

  const [descriptionInput, setDescriptionInput] = useState("");
  const [uploadedSpreadsheet, setUploadedSpreadsheet] = useState<{
    file: File | null;
    progress: number;
    url?: string;
  }>({ file: null, progress: 0 });


  const spreadsheetProvider = SpreadsheetProvider.getInstance();
  const uploadImageProvider = UploadImageProvider.getInstance();

  const getSpreadsheets = useCallback(
    async (
      pageNumber = 1,
      searchTerm = "",
      selectedDate = "",
    ) => {
      if (isFetching.current) return;
      isFetching.current = true;
      const search = new ListSearch({
        searchTerm: searchTerm,
        orderBy: "createdAt",
        limit: itemsPerPage,
        offset: (pageNumber - 1) * itemsPerPage,
        fromDate: selectedDate ? Formatter.dateStringToTimestamp(selectedDate) : undefined,
        toDate: selectedDate ? Formatter.dateStringToTimestamp(selectedDate) : undefined,
      });
      try {
        const response =
          await spreadsheetProvider.getSpreadsheets(search);
        const mappedData = response.spreadsheets.map((spreadsheet) => ({
          id: spreadsheet.id,
          createdAt: Formatter.timestampToDateBR(spreadsheet.createdAt),
          description: spreadsheet.description,
          createdBy: spreadsheet.createdBy,
          path: spreadsheet.path,
        }));
        setData(mappedData);
        setTotalItems(response.totalItems);
      } catch (error) {
        toast.error("Erro ao carregar visitas agendadas");
        console.error(error);
      } finally {
        isFetching.current = false;
      }
    },
    [spreadsheetProvider, itemsPerPage]
  );

  useEffect(() => {
    getSpreadsheets(currentPage);
  }, [itemsPerPage, currentPage, getSpreadsheets]);

  const handleReplace = (id: number) => {
    setSelectedSpreadsheet(id);
    setShowEditModal(true);
  };

  const handleDelete = (id: number) => {
    setSelectedSpreadsheet(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await spreadsheetProvider.deleteSpreadsheet(selectedSpreadsheet!);
      setShowDeleteModal(false);
      toast.success("Planilha excluída com sucesso!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setSelectedSpreadsheet(null);
      getSpreadsheets();
    } catch (error) {
      toast.error("Erro ao excluir planilha");
      console.error(error);
    }
  };

  const handleSpreadsheetFileChange = (files: FileList) => {
    const file = files[0]; // Apenas um arquivo permitido
    setUploadedSpreadsheet({ file, progress: 0 });
    uploadSpreadsheet(file);
  };


  const uploadSpreadsheet = async (file: File) => {
    try {
      setUploadedSpreadsheet((prevImage) => ({ ...prevImage, progress: 0 }));

      const imageUrl = await uploadImageProvider.upload(file);

      if (imageUrl) {
        setUploadedSpreadsheet((prevImage) => ({
          ...prevImage,
          progress: 100,
          url: imageUrl,
        }));
      }
    } catch (error) {
      console.error("Erro ao fazer upload da imagem:", error);
      toast.error(`Erro ao fazer upload de ${file.name}`);
    }
  };

  const handleDeleteUpload = () => {
    setUploadedSpreadsheet({ file: null, progress: 0 });
  };

  const handleCloseModal = () => {
    if (uploadedSpreadsheet) {
      if (
        window.confirm(
          "Tem certeza que deseja fechar? O arquivo carregado será perdido."
        )
      ) {
        setShowAddModal(false);
        setDescriptionInput("");
        setUploadedSpreadsheet({ file: null, progress: 0 });
      }
    } else {
      setShowAddModal(false);
    }
  };

  const handleAddSpreadsheet = async () => {
    console.log(descriptionInput);
    if (!descriptionInput) {
      toast.error("O nome do empreendimento é obrigatório", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (uploadMode === "upload" && !uploadedSpreadsheet.file) {
      toast.error("Você precisa carregar uma planilha", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (uploadMode === "upload" && uploadedSpreadsheet.progress < 100) {
      return toast.error("Por favor, espere o upload da imagem terminar", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }


    try {
      const description = descriptionInput;
      await spreadsheetProvider.createSpreadsheet(uploadMode, description, uploadedSpreadsheet.url);
      getSpreadsheets();
      setShowAddModal(false);
      setDescriptionInput("");
      setUploadedSpreadsheet({ file: null, progress: 0 });
      toast.success("Planilha adicionada com sucesso!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      toast.error("Erro ao adicionar planilha", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error(error);
    }

  };

  const handleEditSpreadsheet = async () => {
    if (!selectedSpreadsheet) return;

    if (uploadMode === "upload" && !uploadedSpreadsheet.file) {
      toast.error("Você precisa carregar uma planilha", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (uploadMode === "upload" && uploadedSpreadsheet.progress < 100) {
      return toast.error("Por favor, espere o upload da imagem terminar", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    try {
      const selected = data.find((sheet) => sheet.id === selectedSpreadsheet);
      await spreadsheetProvider.updateSpreadsheet(
        new Spreadsheet(
          selectedSpreadsheet,
          selected?.description!,
          0,
          0,
          "",
          "",
          uploadedSpreadsheet.url!
        )
      );
      getSpreadsheets();
      setShowEditModal(false);
      setSelectedSpreadsheet(null);
      setUploadedSpreadsheet({ file: null, progress: 0 });
      toast.success("Planilha substituída com sucesso!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      toast.error("Erro ao substituir planilha", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error(error);
    }
  }



  const clearFilters = () => {
    if (searchTerm === "" && selectedDate === "") return;
    setSearchTerm('');
    setSelectedDate('');
    setCurrentPage(1);
    getSpreadsheets(1, '', '');
  };

  const debouncedSearchTerm = useRef(
    debounce((term) => {
      getSpreadsheets(1, term, selectedDate);
    }, 600)
  ).current;

  const debouncedDate = useRef(
    debounce((date) => {
      getSpreadsheets(1, searchTerm, date);
    }, 600)
  ).current;

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    debouncedSearchTerm(searchTerm);
  };

  const handleSearchDateChange = (date: string) => {
    setSelectedDate(date);
    debouncedDate(date);
  };


  return (
    <div className="container-plan-viab">
      <button
        className="add-button-plan-viab"
        onClick={() => setShowAddModal(true)}
      >
        + Adicionar planilha
      </button>

      <div className="filters-plan-viab">
        <input
          type="text"
          placeholder="Buscar por um empreendimento"
          value={searchTerm}
          onChange={(e) => handleSearchTermChange(e.target.value)}
          className="search-input-plan-viab"
        />
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => handleSearchDateChange(e.target.value)}
          className="date-input-plan-viab"
        />
        <button className="clear-filters-button-enterprise" onClick={clearFilters}>
          Limpar filtro
        </button>
      </div>

      <table className="spreadsheet-table-plan-viab">
        <thead>
          <tr>
            <th>ID</th>
            <th>Data</th>
            <th>Nome do empreendimento</th>
            <th>Anexado por</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data.map((sheet) => (
            <tr key={sheet.id}>
              <td>{sheet.id}</td>
              <td>{sheet.createdAt}</td>
              <td>{sheet.description}</td>
              <td>{sheet.createdBy}</td>
              <td className="actions-plan-viab">
                <Tooltip text="Download">
                  <button onClick={() => {
                    const link = document.createElement('a');
                    link.href = sheet.path;
                    link.download = sheet.path;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }} title="Download">
                    <Download size={20} />
                  </button>
                </Tooltip>
                <Tooltip text="Substituir">
                  <button onClick={() => handleReplace(sheet.id)} title="Substituir">
                    <Edit2 size={20} />
                  </button>
                </Tooltip>
                <Tooltip text="Excluir">
                  <button onClick={() => handleDelete(sheet.id)} title="Excluir">
                    <Trash2 size={20} />
                  </button>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="table-footer">
        <div className="items-per-page">
          <span>Exibir por</span>
          <select
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <div className="pagination">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={currentPage === page ? "active" : ""}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            Próximo
          </button>
        </div>
      </div>

      {showAddModal && (
        <div className="modal-overlay-plan-viab">
          <div className="modal-plan-viab">
            <div className="modal-header-plan-viab">
              <h2 className="title-plan-viab">Nova planilha</h2>
              <button onClick={handleCloseModal}>
                <X size={20} />
              </button>
            </div>
            <div className="modal-content-plan-viab">
              <p className="frase-plan-viab">
                Preencha os dados abaixo para adicionar uma nova planilha.
              </p>
              <div className="project-select-container">
                <label
                  htmlFor="project-select"
                  className="project-select-label"
                >
                  Nome do Empreendimento:
                </label>
                <input
                  id="project-select"
                  className="project-select-plan-viab"
                  value={descriptionInput}
                  onChange={(e) => setDescriptionInput(e.target.value)}
                />
              </div>
              <div className="radio-group-plan-viab">
                <p className="frase-escolha">
                  Escolha como adicionar uma planilha
                </p>
                <label>
                  <input
                    type="radio"
                    name="uploadType"
                    checked={uploadMode === "base"}
                    onChange={() => setUploadMode("base")}
                  />
                  Modelo predefinido pelo sistema
                </label>
                <label>
                  <input
                    type="radio"
                    name="uploadType"
                    checked={uploadMode === "upload"}
                    onChange={() => setUploadMode("upload")}
                  />
                  Fazer upload
                </label>
              </div>
              {uploadMode === "upload" && (
                <div className="upload-area-plan-viab">
                  <input
                    type="file"
                    accept=".xlsx,.xls, .xlsm"
                    onChange={(e) => e.target.files && handleSpreadsheetFileChange(e.target.files)}
                    id="file-upload"
                  />
                  <label
                    htmlFor="file-upload"
                    className="upload-label-plan-viab"
                  >
                    <Upload size={24} />
                    <span>
                      Arraste e solte uma planilha aqui, ou clique para
                      selecionar diretamente do seu dispositivo.
                    </span>
                  </label>

                </div>

              )}
              {uploadedSpreadsheet.file && (
                <div className="image-preview-container">
                  <div className="image-preview">
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteUpload()}
                    >
                      &times;
                    </button>
                    <div className="details">
                      <div>
                        <p className="file-name">
                          {uploadedSpreadsheet.file.name}
                        </p>
                        <p className="file-size">
                          {(uploadedSpreadsheet.file.size / 1024 / 1024).toFixed(
                            2
                          )}{" "}
                          Mb
                        </p>
                      </div>
                    </div>

                    <div className="progress-container">
                      <div className="progress-bar-img">
                        <div
                          className="progress-img"
                          style={{ width: `${uploadedSpreadsheet.progress}%` }}
                        ></div>
                      </div>
                      <span className="progress-percentage">
                        {uploadedSpreadsheet.progress}%
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="modal-actions-plan-viab">
                <button onClick={handleCloseModal}>Cancelar</button>
                <button
                  className="primary-plan-viab"
                  onClick={handleAddSpreadsheet}
                >
                  Adicionar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showEditModal && (
        <div className="modal-overlay-plan-viab">
          <div className="modal-plan-viab">
            <div className="modal-header-plan-viab">
              <h2>Substituir planilha</h2>
              <button onClick={() => setShowEditModal(false)}>
                <X size={20} />
              </button>
            </div>
            <div className="modal-content-plan-viab">
              <p className="modal-content-frase">
                Você pode substituir a planilha atual por uma nova. Selecione a
                nova planilha abaixo.
              </p>
              <div className="upload-area-plan-viab">
                <input
                  type="file"
                  accept=".xlsx,.xls, .xlsm"
                  onChange={(e) => e.target.files && handleSpreadsheetFileChange(e.target.files)}
                  id="file-upload"
                />
                <label
                  htmlFor="file-upload"
                  className="upload-label-plan-viab"
                >
                  <Upload size={24} />
                  <span>
                    Arraste e solte uma planilha aqui, ou clique para
                    selecionar diretamente do seu dispositivo.
                  </span>
                </label>
              </div>
              {uploadedSpreadsheet.file && (
                <div className="image-preview-container">
                  <div className="image-preview">
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteUpload()}
                    >
                      &times;
                    </button>
                    <div className="details">
                      <div>
                        <p className="file-name">
                          {uploadedSpreadsheet.file.name}
                        </p>
                        <p className="file-size">
                          {(uploadedSpreadsheet.file.size / 1024 / 1024).toFixed(
                            2
                          )}{" "}
                          Mb
                        </p>
                      </div>
                    </div>

                    <div className="progress-container">
                      <div className="progress-bar-img">
                        <div
                          className="progress-img"
                          style={{ width: `${uploadedSpreadsheet.progress}%` }}
                        ></div>
                      </div>
                      <span className="progress-percentage">
                        {uploadedSpreadsheet.progress}%
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="modal-actions-plan-viab">
                <button onClick={() => setShowEditModal(false)}>Cancelar</button>
                <button
                  className="primary-plan-viab"
                  onClick={handleEditSpreadsheet}
                >
                  Substituir
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="modal-overlay-plan-viab">
          <div className="modal-plan-viab">
            <div className="modal-header-plan-viab">
              <h2>Confirmação de exclusão</h2>
              <button onClick={() => setShowDeleteModal(false)}>
                <X size={20} />
              </button>
            </div>
            <div className="modal-content-plan-viab">
              <p className="modal-content-frase">
                Você tem certeza que deseja excluir essa planilha? Esta ação não
                pode ser desfeita.
              </p>
              <div className="modal-actions-plan-viab">
                <button onClick={() => setShowDeleteModal(false)}>
                  Cancelar
                </button>
                <button className="danger-plan-viab" onClick={confirmDelete}>
                  Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default ViabilitySpreadsheet;
