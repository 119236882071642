import Spreadsheet from "../entities/spreadsheet";

export default class SpreadsheetDTO {
    static fromJson(json: any): Spreadsheet {
        return new Spreadsheet(
            json.id,
            json.description,
            json.createdAt,
            json.updatedAt,
            json.createdBy,
            json.updatedBy,
            json.path,
        )
    }

    static toJson(spreadsheet: Spreadsheet): any {
        return {
            id: spreadsheet.id,
            description: spreadsheet.description,
            documentLink: spreadsheet.path,
        }
    }
}