import ConstructionStep from "../../domain/entities/constructionStep"
import Enterprise from "../../domain/entities/enterprise"
import EnterpriseStatus from "../../domain/entities/valueObjects/enterpriseStatus"
import { ListSearch } from "../../domain/entities/valueObjects/listSearch"
import { AxiosHttp } from "../../http/axios"
import { Enterprises } from "../../domain/entities/enterprises"
import EnterpriseRepository from "../repository/enterpriseRepository"
import CreateEnterprise from "../../domain/entities/createEnterprise"

export default class EnterpriseProvider {
    private static instance: EnterpriseProvider
    private enterpriseRepository: EnterpriseRepository
    authErrors: { email: string, password: string } = { email: '', password: '' }

    private constructor() {
        this.enterpriseRepository = EnterpriseRepository.getInstance(AxiosHttp.getInstance())
    }

    static getInstance(): EnterpriseProvider {
        if (!EnterpriseProvider.instance) {
            EnterpriseProvider.instance = new EnterpriseProvider()
        }
        return EnterpriseProvider.instance
    }

    async createEnterprise(enterprise: CreateEnterprise) {
        return await this.enterpriseRepository.createEnterprise(enterprise)
    }

    async updateEnterprise(enterprise: Enterprise) {
        return await this.enterpriseRepository.updateEnterprise
    }

    async getEnterprisesName(): Promise<Enterprise[]> {
        return await this.enterpriseRepository.getEnterprisesName()
    }

    async getEnterprises(search: ListSearch): Promise<Enterprises> {
        return await this.enterpriseRepository.getEnterprises(search)
    }

    async getEnterpriseById(id: string): Promise<Enterprise> {
        return await this.enterpriseRepository.getEnterpriseById(id)
    }

    async updateEnterpriseStatus(id: string, status: EnterpriseStatus) {
        return await this.enterpriseRepository.updateEnterpriseStatus(id, status)
    }

    async updateEnterpriseReleaseType(id: string, releaseTypeId: string) {
        return await this.enterpriseRepository.updateEnterpriseReleaseType(id, releaseTypeId)
    }

    async getEnterpriseConstructionSteps(id: string) {
        return await this.enterpriseRepository.getEnterpriseConstructionSteps(id)
    }

    async getEnterpriseConstructionStepById(enterpriseId: string, constructionStepId: string) {
        return await this.enterpriseRepository.getEnterpriseConstructionStepById(enterpriseId, constructionStepId)
    }

    async createEnterpriseConstructionStep(enterpriseId: string, constructionStep: ConstructionStep) {
        return await this.enterpriseRepository.createEnterpriseConstructionStep(enterpriseId, constructionStep)
    }

    async createSubStep(constructionStepId: string, subStep: ConstructionStep) {
        return await this.enterpriseRepository.createSubStep(constructionStepId, subStep)
    }

    async updateConstructionStepStatus(constructionStepId: string, status: string) {
        return await this.enterpriseRepository.updateConstructionStepStatus(constructionStepId, status)
    }

    async addCommentToConstructionSubStep(constructionStepId: string, body: string) {
        return await this.enterpriseRepository.addCommentToConstructionSubStep(constructionStepId, body)
    }

    async getSubStepComments(constructionStepId: string) {
        return await this.enterpriseRepository.getSubStepComments(constructionStepId)
    }

    async addImageToConstructionSubStep(constructionStepId: string, image: string | File) {
        return await this.enterpriseRepository.addImageToConstructionSubStep(constructionStepId, image)
    }

    async getSubStepImages(constructionStepId: string) {
        return await this.enterpriseRepository.getSubStepImages(constructionStepId)
    }

    async addEnterpriseImage(enterpriseId: string, image: string | File, isFavorite: boolean) {
        return await this.enterpriseRepository.addEnterpriseImage(enterpriseId, image, isFavorite)
    }

    async deleteEnterpriseImage(enterpriseId: string, imageId: string) {
        return await this.enterpriseRepository.deleteEnterpriseImage(enterpriseId, imageId)
    }
}