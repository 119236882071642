export default class ConstructionStatus {
    public readonly PENDING = 'pending';
    public readonly IN_PROGRESS = 'in_progress';
    public readonly FINISHED = 'finished';

    public readonly ALL = [
        this.PENDING,
        this.IN_PROGRESS,
        this.FINISHED
    ];

    public constructionStatusMap: Record<string, string> = {
        'pending': 'Pendente',
        'in_progress': 'Em Progresso',
        'finished': 'Concluído',
        'unknown': 'Desconhecido'
    };

    constructor(private constructionStatus: string) {
        this.constructionStatus = this.ALL.includes(constructionStatus) ? constructionStatus : 'unknown';
    }

    public getStatus(): string {
        return this.constructionStatus;
    }

    public getFormatted(): string {
        return this.constructionStatusMap[this.constructionStatus];
    }
}