import UserData from "./userData";

export default class ScheduleVisit {
    constructor(
        public readonly id: string,
        public readonly date: number,
        public readonly createdAt: number,
        public readonly obs: string,
        public readonly requester: UserData | undefined = undefined,
        public readonly responsible: UserData | undefined = undefined,
        public readonly enterpriseName: string,
    ) {}
}
