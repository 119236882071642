export default class EnterpriseStatus {
    public readonly AVAILABLE      = 'available';
    public readonly UNAVAILABLE   = 'not_available';

    public readonly ALL = [
        this.AVAILABLE,
        this.UNAVAILABLE,
    ];

    public enterpriseStatusMap: Record<string, string> = {
        'available': 'Ativo',
        'not_available': 'Inativo',
        'unknown': 'Desconhecido'
    };

    constructor(private enterpriseStatus: string) {
        this.enterpriseStatus = this.ALL.includes(enterpriseStatus) ? enterpriseStatus : 'unknown';
    }

    public getStatus(): string {
        return this.enterpriseStatus;
    }

    public getFormatted(): string {
        return this.enterpriseStatusMap[this.enterpriseStatus];
    }
}