export default class Ownership {
    public readonly THIRD = 'third';
    public readonly OWN = 'own';

    public readonly ALL = [
        this.THIRD,
        this.OWN
    ];

    public ownershipMap: Record<string, string> = {
        'third': 'Terceiros',
        'own': 'Próprio',
    };

    constructor(private ownership: string) {
        this.ownership = this.ALL.includes(ownership) ? ownership : 'unknown';
    }

    public getMethod(): string {
        return this.ownership;
    }

    public getFormatted(): string {
        return this.ownershipMap[this.ownership];
    }

    static getAllTypes(): string[] {
        return new Ownership('').ALL;
    }
}