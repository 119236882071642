import { Address } from "./address";
import ConstructionCompany from "./constructionCompany";
import ConstructionStep from "./constructionStep";
import ProjectPlan from "./projectPlan";
import Unit from "./unit";
import EnterpriseStatus from "./valueObjects/enterpriseStatus";
import Image from "./valueObjects/image";
import PropertyCategory from "./valueObjects/propertyCategory";
import ReleaseType from "./valueObjects/releaseType";

export default class Enterprise {
    constructor(
        public id = '',
        public name = '',
        public description = '',
        public category: PropertyCategory = new PropertyCategory(),
        public releaseType: ReleaseType = new ReleaseType(),
        public constructionCompany: ConstructionCompany = new ConstructionCompany(),
        public createdAt = 0,
        public deliveryEstimation = 0,
        public tags = [],
        public services = [],
        public commonAreas = [],
        public address = new Address(),
        public status: EnterpriseStatus = new EnterpriseStatus('unknown'),
        public units: Unit[] = [],
        public projectPlans: ProjectPlan[] = [],
        public images: Image[] = [],
        public constructionSteps: ConstructionStep[] = []
    ) {}
}