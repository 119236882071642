import { Address } from "../entities/address";
import Lead from "../entities/lead";
import AddressDTO from "./addressDTO";

export default class LeadDTO {
    public static fromJson(json: any): Lead {
        return new Lead({
            id: json.id,
            email: json.email,
            document: json.document,
            phone: json.phone,
            name: json.name,
            motherName: json.motherName,
            monthlyIncome: json.monthlyIncome,
            patrimony: json.patrimony,
            birthDate: json.birthDate,
            createdAt: json.createdAt,
            address: json.address ? AddressDTO.fromJson(json.address) : new Address()
        });
    }
}