import { Address } from "./address";
import User from "./user";

export default class Profile {
    constructor(
        public id = '',
        public name = '',
        public phone = '',
        public document = '',
        public address: Address = new Address(),
        public user: User = new User(),
        public image: File | null = null
    ) {}
}