import { AxiosHttp } from "../../http/axios";
import PropertyCategory from "../../domain/entities/valueObjects/propertyCategory";
import PropertyCategoryRepository from "../repository/propertyCategoryRepository";

export default class PropertyCategoryProvider {
    private static instance: PropertyCategoryProvider;
    private propertyCategoryRepository: PropertyCategoryRepository;
    private propertyCategorys: PropertyCategory[] = [];

    private constructor() {
        this.propertyCategoryRepository = PropertyCategoryRepository.getInstance(AxiosHttp.getInstance());
        this.loadPropertyCategorys();
    }

    static getInstance(): PropertyCategoryProvider {
        if (!PropertyCategoryProvider.instance) {
            PropertyCategoryProvider.instance = new PropertyCategoryProvider();
        }
        return PropertyCategoryProvider.instance;
    }

    private async loadPropertyCategorys() {
        this.propertyCategorys = await this.propertyCategoryRepository.getPropertyCategorys();
    }

    getPropertyCategorys(): PropertyCategory[] {
        return this.propertyCategorys;
    }

getPropertyCategoryById(id: string | number): PropertyCategory | undefined {
    return this.propertyCategorys.find(propertyCategory => propertyCategory.id.toString() === id.toString());
}
}