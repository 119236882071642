import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faHandshake, faSignOutAlt, faHouse, faTreeCity, faUserGroup, faBox, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import '../../styles/components/_sidebar.scss';
import Logo from '../../assets/images/homecorp-logo.png';
import LogoSM from '../../assets/images/homecorp-logo-small.png';
import User from '../../assets/images/avatar.png';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ViabilitySpreadsheet from '../ViabilitySpreadsheet';
import { AxiosHttp } from '../../http/axios';

const Sidebar: React.FC<{ setHeaderTitle: (title: string) => void }> = ({ setHeaderTitle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showSubmenuProposta, setShowSubmenuProposta] = useState(false);
  const [showSubmenuEmpreedimento, setShowSubmenuEmpreedimento] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [showSubmenuOutros, setShowSubmenuOutros] = useState(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  const handlePropostasClick = () => {
    setHeaderTitle('Propostas');
    setShowSubmenuProposta(!showSubmenuProposta);
  };

  const handleNovaProposta = () => {
    navigate('/propostas/criar');
    setShowSubmenuProposta(false);
  };

  const handleVisualizarPropostas = () => {
    navigate('/propostas/visualizar');
    setShowSubmenuProposta(false);
  };

  const handleEmpreedimentosClick = () => {
    setHeaderTitle('Empreedimentos');
    setShowSubmenuEmpreedimento(!showSubmenuEmpreedimento);
  };

  const handleNovoEmpreendimento = () => {
    navigate('/empreendimentos/novo'); // Rota corrigida
    setShowSubmenuEmpreedimento(false);
  };

  const handleVisualizarEmpreedimentos = () => {
    navigate('/empreendimentos/visualizar'); // Rota corrigida
    setShowSubmenuEmpreedimento(false);
  };

  const handleOutrosClick = () => {
    setHeaderTitle('Outros');
    setShowSubmenuOutros(!showSubmenuOutros);
  };

  const handleFAQOutros = () => {
    navigate('/outros/FAQ'); 
    setShowSubmenuOutros(false);
  };

  const handleAgendaOutros = () => {
    navigate('/outros/scheduling'); 
    setShowSubmenuOutros(false);
  };

  const handlePlanilhaOutros = () => {
    navigate('/outros/ViabilitySpreadsheet'); 
    setShowSubmenuOutros(false);
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) setEmail(storedEmail);
  }, []);

  const userName = email ? email.split('@')[0] : 'Usuário';

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="container">
        <div className="logo">
          <img src={isOpen ? Logo : LogoSM} alt="Logo" />
        </div>

        <ul className="menu">
          <li onClick={() => { setHeaderTitle('Home'); navigate('/home'); }}>
            <FontAwesomeIcon icon={faHouse} className="menu-icon" />
            {isOpen && <span>Home</span>}
          </li>

          <li onClick={handlePropostasClick}>
            <FontAwesomeIcon icon={faHandshake} className="menu-icon" />
            {isOpen && (
              <span>
                Propostas
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`submenu-icon ${showSubmenuProposta ? 'open' : ''}`}
                />
              </span>
            )}
          </li>

          {/* Submenu de Propostas */}
          {showSubmenuProposta && isOpen && (
            <ul className="submenu">
              <li onClick={handleNovaProposta}>Nova Proposta</li>
              <li onClick={handleVisualizarPropostas}>Visualizar Propostas</li>
            </ul>
          )}

       

          <li onClick={handleEmpreedimentosClick}>
            <FontAwesomeIcon icon={faTreeCity} className="menu-icon" />
            {isOpen && (
              <span>
                Empreedimentos
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`submenu-icon ${showSubmenuEmpreedimento ? 'open' : ''}`}
                />
              </span>
            )}
          </li>

          {/* Submenu de Empreedimentos */}
          {showSubmenuEmpreedimento && isOpen && (
            <ul className="submenu">
              <li onClick={handleNovoEmpreendimento}>Novo Empreendimento</li>
              <li onClick={handleVisualizarEmpreedimentos}>Ver Empreendimentos</li>
            </ul>
          )}

          <li onClick={() => { setHeaderTitle('Leads'); navigate('/leads'); }}>
            <FontAwesomeIcon icon={faUserGroup} className="menu-icon" />
            {isOpen && <span>Leads</span>}
          </li>
         
          <li onClick={handleOutrosClick}>
            <FontAwesomeIcon icon={faBox} className="menu-icon" />
            {isOpen && (
              <span>
                Outros
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`submenu-icon ${showSubmenuOutros ? 'open' : ''}`}
                />
              </span>
            )}
          </li>

          {/* Submenu de Outros */}
          {showSubmenuOutros && isOpen && (
            <ul className="submenu">
              <li onClick={handleFAQOutros}>Topicos Frequentes</li>
              <li onClick={handleAgendaOutros}>Agenda de visitas</li>
              <li onClick={handlePlanilhaOutros}>Planilha de viabilidade</li>
            </ul>
          )}
        </ul>
      </div>

      {/* Informações do usuário */}
      <div className="user-info">
        <img src={User} alt="User" />
        <div className="user-details">
          <span>{userName}</span>
          <span>{email}</span>
        </div>
      </div>

      {/* Logout */}
      <ul className="menu logout">
        <li onClick={AxiosHttp.logout}>
          <FontAwesomeIcon icon={faSignOutAlt} className="menu-icon-out" />
          {isOpen && <span>Sair</span>}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
