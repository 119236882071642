import ConstructionCompanyDTO from "../../domain/dto/constructionCompanyDTO";
import ConstructionCompany from "../../domain/entities/constructionCompany";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class ConstructionCompanyRepository {
    private static instance: ConstructionCompanyRepository | null = null

    constructor(
        private http: Http
    ) {}

    static getInstance(http: Http): ConstructionCompanyRepository {
        if (!ConstructionCompanyRepository.instance) {
            ConstructionCompanyRepository.instance = new ConstructionCompanyRepository(http)
        }
        return ConstructionCompanyRepository.instance
    }

    async createConstructionCompany(constructionCompany: ConstructionCompany) {
        const request = new HttpRequest(
            ApiRoutes.constructionCompany,
            constructionCompany
        );

        return await this.http.post<ConstructionCompany>(request);
    }

    async getConstructionCompanies(): Promise<ConstructionCompany[]> {
        const request = new HttpRequest(
            ApiRoutes.constructionCompany,
        );

        const response = await this.http.get<ConstructionCompany[]>(request);
        return response.map((constructionCompany) => ConstructionCompanyDTO.fromJson(constructionCompany));
    }

    async getConstructionCompanyById(id: string): Promise<ConstructionCompany> {
        const request = new HttpRequest(
            `${ApiRoutes.constructionCompany}/${id}`,
        );

        const response = await this.http.get<ConstructionCompany>(request);
        return ConstructionCompanyDTO.fromJson(response);
    }
}