import { Proposal } from "../../domain/entities/proposal"
import ProposalDocument from "../../domain/entities/proposalDocument"
import { Proposals } from "../../domain/entities/proposals"
import { ListSearch } from "../../domain/entities/valueObjects/listSearch"
import PreApprovedValue from "../../domain/entities/valueObjects/preApprovedValue"
import ProposalStatus from "../../domain/entities/valueObjects/proposalStatus"
import { AxiosHttp } from "../../http/axios"
import ProposalRepository from "../repository/proposalRepository"

export default class ProposalProvider {
    private static instance: ProposalProvider
    private proposalRepository: ProposalRepository
    authErrors: { email: string, password: string } = { email: '', password: '' }

    private constructor() {
        this.proposalRepository = ProposalRepository.getInstance(AxiosHttp.getInstance())
    }

    static getInstance(): ProposalProvider {
        if (!ProposalProvider.instance) {
            ProposalProvider.instance = new ProposalProvider()
        }
        return ProposalProvider.instance
    }

    async createProposal(proposal: Proposal): Promise<void> {
        return await this.proposalRepository.createProposal(proposal)
    }

    async getProposals(listSearch: ListSearch): Promise<Proposals> {
        return await this.proposalRepository.getProposals(listSearch)
    }

    async getProposalById(id: string): Promise<Proposal> {
        return await this.proposalRepository.getProposalById(id)
    }

    async updateProposalStatus(id: string, proposalStatus: ProposalStatus, statusDetail: string): Promise<void> {
        return await this.proposalRepository.updateProposalStatus(id, proposalStatus, statusDetail)
    }

    async sendProposalTerm(id: string, phone: string, email: string): Promise<void> {
        return await this.proposalRepository.sendProposalTerm(id, phone, email)
    }

    async createPreApprovedValue(id: string, preApprovedValue: PreApprovedValue): Promise<PreApprovedValue> {
        return await this.proposalRepository.createPreApprovedValue(id, preApprovedValue)
    }

    async deletePreApprovedValue(proposalId: string, preApprovedValueId: string): Promise<void> {
        return await this.proposalRepository.deletePreApprovedValue(proposalId, preApprovedValueId)
    }

    async uploadProposalDocument(proposalId: string, ProposalDocument: ProposalDocument): Promise<void> {
        return await this.proposalRepository.uploadProposalDocument(proposalId, ProposalDocument)
    }

    async getProposalDocument(id: string): Promise<string> {
        return await this.proposalRepository.getProposalDocument(id)
    }

    async addProposalObservation(id: string, observation: string): Promise<void> {
        return await this.proposalRepository.addProposalObservation(id, observation)
    }

    async deleteProposalObservation(proposalId: string, observationId: string): Promise<void> {
        return await this.proposalRepository.deleteProposalObservation(proposalId, observationId)
    }

}