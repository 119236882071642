export default class ConstructionCompany {
    constructor(
        public id = '',
        public name = '',
        public fantasyName = '',
        public cnpj = '',
        public address = '',
        public city = '',
        public state = '',
        public phone = '',
        public email = '',
        public responsible = '',
        public responsiblePhone = '',
        public responsibleEmail = '',
        public license: File | null = null,
    ) {}
}