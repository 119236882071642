import React, { useState, useEffect } from 'react';
import '../styles/components/_drawer.scss';

const DrawerFilter: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const [selectedState, setSelectedState] = useState(''); // Estado selecionado
  const [cities, setCities] = useState([]); // Cidades carregadas da API
  const [selectedCity, setSelectedCity] = useState(''); // Cidade selecionada
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [minArea, setMinArea] = useState('');
  const [maxArea, setMaxArea] = useState('');

  const [selectedRooms, setSelectedRooms] = useState(null);
  const [selectedBathrooms, setSelectedBathrooms] = useState(null);
  const [selectedParking, setSelectedParking] = useState(null);

  // Função para buscar cidades quando um estado é selecionado
  useEffect(() => {
    if (selectedState) {
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState}/municipios`)
        .then((response) => response.json())
        .then((data) => {
          setCities(data); // Atualiza o state com as cidades recebidas
          setSelectedCity(''); // Limpa a cidade selecionada ao trocar de estado
        })
        .catch((error) => {
          console.error('Erro ao buscar cidades:', error);
        });
    }
  }, [selectedState]);

  const handleRoomSelect = (value: any) => {
    setSelectedRooms(value);
  };

  const handleBathroomSelect = (value: any) => {
    setSelectedBathrooms(value);
  };

  const handleParkingSelect = (value: any) => {
    setSelectedParking(value);
  };

  const formatPrice = (value: any) => {
    value = value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número
    value = (Number(value) / 100).toFixed(2) + ''; // Converte para número e adiciona as casas decimais
    value = value.replace('.', ','); // Substitui o ponto pela vírgula
    value = value.replace(/(\d)(?=(\d{3})+,)/g, '$1.'); // Adiciona o ponto como separador de milhar
    return 'R$ ' + value; // Adiciona o símbolo de moeda
  };

  // Função para aplicar a máscara de área
  const formatArea = (value: any) => {
    value = value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número
    return value + ' M²'; // Adiciona o "M²" no final
  };

  const handleMinPriceChange = (e: any) => {
    setMinPrice(formatPrice(e.target.value));
  };

  const handleMaxPriceChange = (e: any) => {
    setMaxPrice(formatPrice(e.target.value));
  };

  const handleMinAreaChange = (e: any) => {
    setMinArea(formatArea(e.target.value));
  };

  const handleMaxAreaChange = (e: any) => {
    setMaxArea(formatArea(e.target.value));
  };

  return (
    <div className={`drawer ${isOpen ? 'open' : ''}`}>
      <div className="drawer-content">
        <div className="filter-form">
          <label>Estado:</label>
          <select
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            <option value="">Selecione um estado</option>
            <option value="12">Acre</option>
            <option value="27">Alagoas</option>
            <option value="16">Amapá</option>
            <option value="13">Amazonas</option>
            <option value="29">Bahia</option>
            <option value="23">Ceará</option>
            <option value="53">Distrito Federal</option>
            <option value="32">Espírito Santo</option>
            <option value="52">Goiás</option>
            <option value="21">Maranhão</option>
            <option value="51">Mato Grosso</option>
            <option value="50">Mato Grosso do Sul</option>
            <option value="31">Minas Gerais</option>
            <option value="15">Pará</option>
            <option value="25">Paraíba</option>
            <option value="41">Paraná</option>
            <option value="26">Pernambuco</option>
            <option value="22">Piauí</option>
            <option value="33">Rio de Janeiro</option>
            <option value="24">Rio Grande do Norte</option>
            <option value="43">Rio Grande do Sul</option>
            <option value="11">Rondônia</option>
            <option value="14">Roraima</option>
            <option value="42">Santa Catarina</option>
            <option value="35">São Paulo</option>
            <option value="28">Sergipe</option>
            <option value="17">Tocantins</option>
          </select>

          <label>Cidade:</label>
          <select
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            disabled={!selectedState} // Desabilita o campo se nenhum estado for selecionado
          >
            <option value="">Selecione uma cidade</option>
            {cities.map((city: any) => (
              <option key={city.id} value={city.nome}>
                {city.nome}
              </option>
            ))}
          </select>

          <label>Tipo de empreendimento:</label>
          <select>
            <option value="">Tipo de empreendimento 1</option>
            <option value="">Tipo de empreendimento 2</option>
            <option value="">Tipo de empreendimento 3</option>
            <option value="">Tipo de empreendimento 4</option>
            <option value="">Tipo de empreendimento 5</option>
            <option value="">Tipo de empreendimento 6</option>
            <option value="">Tipo de empreendimento 7</option>
            <option value="">Tipo de empreendimento 8</option>
          </select>

          <label>Preço:</label>
          <div className="price-range">
            <input
              type="text"
              placeholder="R$ Mínimo"
              value={minPrice}
              onChange={handleMinPriceChange}
            />
            <label className="duration">Até</label>
            <input
              type="text"
              placeholder="R$ Máximo"
              value={maxPrice}
              onChange={handleMaxPriceChange}
            />
          </div>

          <label>Área em metros:</label>
          <div className="area-range">
            <input
              type="text"
              placeholder="M² Mínimo"
              value={minArea}
              onChange={handleMinAreaChange}
            />
            <label className="duration">Até</label>
            <input
              type="text"
              placeholder="M² Máximo"
              value={maxArea}
              onChange={handleMaxAreaChange}
            />
          </div>

          <label>Quantidade de quartos:</label>
          <div className="options">
            {[1, 2, 3, 4].map((value) => (
              <button
                key={value}
                className={selectedRooms === value ? 'active' : ''}
                onClick={() => handleRoomSelect(value)}
              >
                {value}+
              </button>
            ))}
          </div>

          <label>Quantidade de banheiros:</label>
          <div className="options">
            {[1, 2, 3, 4].map((value) => (
              <button
                key={value}
                className={selectedBathrooms === value ? 'active' : ''}
                onClick={() => handleBathroomSelect(value)}
              >
                {value}+
              </button>
            ))}
          </div>

          <label>Quantidade de vagas:</label>
          <div className="options">
            {[1, 2, 3, 4].map((value) => (
              <button
                key={value}
                className={selectedParking === value ? 'active' : ''}
                onClick={() => handleParkingSelect(value)}
              >
                {value}+
              </button>
            ))}
          </div>

          <div className="actions">
            <button className="cancel" onClick={onClose}>Cancelar</button>
            <button className="apply">Aplicar filtro</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerFilter;
