export class PreApprovedType {
    public readonly CNPJ = 'cnpj';
    public readonly PROPERTIES = 'properties';
    public readonly VEHICLES = 'vehicles';

    public readonly ALL = [
        this.CNPJ,
        this.PROPERTIES,
        this.VEHICLES
    ];

    public preApprovedTypeMap: Record<string, string> = {
        'cnpj': 'CNPJ',
        'properties': 'Imóveis',
        'vehicles': 'Veículos',
        'unknown': 'Desconhecido'
    };

    constructor(private preApprovedType: string) {
        this.preApprovedType = this.ALL.includes(preApprovedType) ? preApprovedType : 'unknown';
    }

    public getType(): string {
        return this.preApprovedType;
    }

    public getFormatted(): string {
        return this.preApprovedTypeMap[this.preApprovedType];
    }

    public isCnpj(): boolean {
        return this.preApprovedType === this.CNPJ;
    }

    public isProperties(): boolean {
        return this.preApprovedType === this.PROPERTIES;
    }
    

}