import axios from 'axios';
import ApiRoutes from "../../http/routes";

export default class UploadImageRepository {
    private static instance: UploadImageRepository | null = null;

    private constructor() {}

    static getInstance(): UploadImageRepository {
        if (!UploadImageRepository.instance) {
            UploadImageRepository.instance = new UploadImageRepository();
        }
        return UploadImageRepository.instance;
    }

    async upload(file: File): Promise<string> {
        try {
            const formData = new FormData();
            formData.append('file', file, file.name);

            const response = await axios.post(ApiRoutes.uploader, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const token = response.data.token;
            const url = `${ApiRoutes.uploader}/?token=${token}`;
            return url;
            
        } catch (error) {
            console.error('Error uploading file:', error);
            throw new Error('Failed to upload image');
        }
    }
}