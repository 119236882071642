export default class UserType {
    public readonly SYS_ADMIN      = 'sysAdmin';
    public readonly TENANT_ADMIN   = 'tenantAdmin';
    public readonly ASSISTANT      = 'assistant';
    public readonly CORRESPONDENT = 'correspondent';
    public readonly PROFILE        = 'profile';

    public readonly ALL = [
        this.SYS_ADMIN,
        this.TENANT_ADMIN,
        this.ASSISTANT,
        this.CORRESPONDENT,
        this.PROFILE
    ];

    public userTypeMap: Record<string, string> = {
        'sysAdmin': 'Administrador do sistema',
        'tenantAdmin': 'Administrador do tenant',
        'assistant': 'Suporte',
        'correspondent': 'Correspondente',
        'unknown': 'Desconhecido'
    };

    constructor(private type: string) {
        this.type = this.ALL.includes(type) ? type : 'unknown';
    }

    public getUserType(): string {
        return this.type;
    }

    public getFormatted(): string {
        return this.userTypeMap[this.type];
    }
}