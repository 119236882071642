import React, { useState } from 'react';
import '../../styles/components/_token.scss'; // Adicione o arquivo de estilo
import logo from '../../assets/images/homecorp-logo.png'; 
import header from '../../assets/images/header.png'; 
import AuthProvider from '../../infra/provider/authProvider';
import PopupError from '../popup/PopupError';
import { useNavigate } from 'react-router';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const authProvider = AuthProvider.getInstance();
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [popup, setPopup] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    await authProvider.forgotPassword(email)
      .then(() => {
        setIsSubmitted(true)
        navigate('/token', { state: { email } })
      })
      .catch((err: any) => {
        setError(err.message);
        setPopup(true);
      });
  };

  return (
    <div className="token-container">
      {/* Coluna da esquerda com a imagem */}
      <div className="login-image">
        <img src={header} alt="Construtech" />
      </div>

      {/* Coluna da direita com o formulário */}
      <div className="token-form">
        <div className="form-container">
          <img src={logo} alt="Homecorp logo" className="logo" />
          <h1>Vamos enviar um código para o seu e-mail!</h1>
          <p>Para redefinir sua senha, preencha seu e-mail abaixo.</p>

          {/* Formulário de envio do código */}
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">E-mail:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Enviar Código</button>
          </form>

          {/* Condicional para exibir a mensagem de sucesso */}
          {isSubmitted && <p className="success-message">Código de recuperação enviado com sucesso!</p>}
        </div>
      </div>
      {popup && <PopupError message={error} onClose={() => setPopup(false)} />}
    </div>
  );
};

export default ForgotPassword;
