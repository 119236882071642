export default class UnitStatus {
    public readonly AVAILABLE     = 'available';
    public readonly UNAVAILABLE   = 'not_available';
    public readonly RESERVED      = 'reserved';
    public readonly SOLD          = 'sold';

    public readonly ALL = [
        this.AVAILABLE,
        this.UNAVAILABLE,
        this.RESERVED,
        this.SOLD
    ];

    public unitStatusMap: Record<string, string> = {
        'available': 'Ativo',
        'not_available': 'Inativo',
        'reserved': 'Reservado',
        'sold': 'Vendido',
        'unknown': 'Desconhecido'
    };

    constructor(private unitStatus: string) {
        this.unitStatus = this.ALL.includes(unitStatus) ? unitStatus : 'unknown';
    }

    public getStatus(): string {
        return this.unitStatus;
    }

    public getFormatted(): string {
        return this.unitStatusMap[this.unitStatus];
    }
}