import PropertyCategory from "../entities/valueObjects/propertyCategory";

export default class PropertyCategoryDTO {
    static fromJson(json: any): PropertyCategory {
        return new PropertyCategory(
            json.id,
            json.name,
            json.description
        )
    }
}