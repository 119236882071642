import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/components/_empreedimentos.scss";
import EnterpriseProvider from "../../infra/provider/enterpriseProvider";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";
import { debounce } from "lodash";
import { Formatter } from "../../utils/formatter";

interface TableData {
  id: string;
  createdAt: string;
  name: string;
  category: string;
  city: string;
  state: string;
  address: string;
  status: string;
}

const Enterprises = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<TableData[]>([]);
  const navigate = useNavigate();
  const isFetching = useRef(false);

  const enterpriseProvider = EnterpriseProvider.getInstance();

  const getEnterprises = useCallback(
    async (pageNumber = 1, searchTerm = "", selectedDate = "", selectedStatus = "") => {
      if (isFetching.current) return;
      isFetching.current = true;
      let dateFormatted = undefined;
      if (selectedDate) {
        const date = new Date(selectedDate);
        dateFormatted = Formatter.dateToTimestamp(date).toString();
      }
      const search = new ListSearch({
        searchTerm: searchTerm,
        status: selectedStatus,
        orderBy: "createdAt",
        limit: itemsPerPage,
        offset: (pageNumber - 1) * itemsPerPage,
        fromDate: selectedDate ? Formatter.dateStringToTimestamp(selectedDate) : undefined,
        toDate: selectedDate ? Formatter.dateStringToTimestamp(selectedDate) : undefined,
      });
      const response = await enterpriseProvider.getEnterprises(search);
      const mappedData = response.enterprises.map((enterprise) => ({
        id: enterprise.id,
        createdAt: Formatter.timestampToDateBR(enterprise.createdAt, false),
        name: enterprise.name,
        category: enterprise.category.name,
        city: enterprise.address.city,
        state: enterprise.address.uf,
        address: enterprise.address.street,
        status: enterprise.status.getFormatted(),
      }));

      setData(mappedData);
      setTotalItems(response.totalItems);
      isFetching.current = false;
    },
    [itemsPerPage, enterpriseProvider]
  );

  useEffect(() => {
    getEnterprises(currentPage);
  }, [itemsPerPage, currentPage, getEnterprises]);

  const handleRowClick = (id: string) => {
    // Navega para a página de detalhes, passando o id
    navigate(`/empreendimentos/${id}`);
  };

  const debouncedSearchTerm = useRef(
    debounce((term) => {
      getEnterprises(1, term, selectedDate); // Certifique-se de que os argumentos estão corretos
    }, 600)
  ).current;

  const debouncedDate = useRef(
    debounce((date: any) => {
      getEnterprises(1, searchTerm, date);
    }, 600)
  ).current;

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    debouncedSearchTerm(searchTerm);
  };

  const handleSearchDateChange = (date: string) => {
    setSelectedDate(date);
    debouncedDate(date);
  };

  const handleClearFilters = () => {
    if (searchTerm === "" && selectedDate === "" && selectedStatus === "") return;
    setSearchTerm("");
    setSelectedStatus("");
    setSelectedDate("");
    setCurrentPage(1); // Opcional, se quiser resetar para a primeira página
    getEnterprises(1, "", "");
  };


  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="data-table-container">
      <div className="filters-container">
        <div className="search-container">
          <input
            type="text"
            placeholder="Busque por um nome, CPF, ID etc..."
            value={searchTerm}
            onChange={(e) => handleSearchTermChange(e.target.value)}
          />
          <button className="search-button">{/* Botão de busca */}</button>
        </div>
        <select
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
            getEnterprises(1, searchTerm, selectedDate, e.target.value);
          }}
          className="status-select"
        >
          <option value="">Selecione um status</option>
          <option value="available">Ativo</option>
          <option value="not_available">Inativo</option>
        </select>
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => handleSearchDateChange(e.target.value)}
          className="date-input"
        />
        <button
          onClick={handleClearFilters}
          className="clear-filters-button-enterprise"
        >
          Limpar filtros
        </button>
      </div>

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Data de criação</th>
              <th>Nome</th>
              <th>Categoria</th>
              <th>Cidade</th>
              <th>Estado</th>
              <th>Endereço</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(item.id)} // Adicionando o clique na linha
              >
                <td>{item.id}</td>
                <td>{item.createdAt}</td>
                <td>{item.name}</td>
                <td>{item.category}</td>
                <td>{item.city}</td>
                <td>{item.state}</td>
                <td>{item.address}</td>
                <td>{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="table-footer">
        <div className="items-per-page">
          <span>Exibir por</span>
          <select
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(parseInt(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <div className="pagination">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={currentPage === page ? "active" : ""}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            Próximo
          </button>
        </div>
      </div>
    </div>
  );
};

export default Enterprises;
