import ListSearchDTO from "../../domain/dto/listSearchDTO";
import PreApprovedValueDTO from "../../domain/dto/preApprovedValueDTO";
import ProposalDocumentDTO from "../../domain/dto/proposalDocumentDTO";
import ProposalDTO from "../../domain/dto/proposalDTO";
import ProposalsDTO from "../../domain/dto/proposalsDTO";
import { Proposal } from "../../domain/entities/proposal";
import ProposalDocument from "../../domain/entities/proposalDocument";
import { Proposals } from "../../domain/entities/proposals";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";
import PreApprovedValue from "../../domain/entities/valueObjects/preApprovedValue";
import ProposalStatus from "../../domain/entities/valueObjects/proposalStatus";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class ProposalRepository {
    private static instance: ProposalRepository | null = null

    constructor(
        private http: Http
    ) {}

    static getInstance(http: Http): ProposalRepository {
        if (!ProposalRepository.instance) {
            ProposalRepository.instance = new ProposalRepository(http)
        }
        return ProposalRepository.instance
    }

    async createProposal(proposal: Proposal): Promise<void> {
        const request = new HttpRequest(
            ApiRoutes.proposal,
            ProposalDTO.toJson(proposal)
        );

        return await this.http.post(request);
    }

    async getProposals(listSearch: ListSearch): Promise<Proposals> {
        const request = new HttpRequest(
            ApiRoutes.proposal,
            undefined,
            ListSearchDTO.toJson(listSearch)
        );

        const response = await this.http.get<Proposals>(request);
        return ProposalsDTO.fromJson(response);
    }

    async getProposalById(id: string): Promise<Proposal> {
        const request = new HttpRequest(`${ApiRoutes.proposal}/${id}`);

        const response = await this.http.get<Proposal>(request);
        return ProposalDTO.fromJson(response);
    }

    // mandar resumo da proposta pra assinatura no clicksign
    async sendProposalTerm(id: string, phone: string, email: string): Promise<void> {
        const request = new HttpRequest(
            ApiRoutes.sendProposalTerm.replace('{id}', id),
            { phone, email }
        );

        return await this.http.post(request);
    }

    async updateProposalStatus(id: string, status: ProposalStatus, statusDetail: string): Promise<void> {
        const request = new HttpRequest(
            ApiRoutes.updateProposalStatus.replace('{id}', id),
            { status: status.getStatus(), statusDetail }
        );

        return await this.http.put(request);
    }

    async createPreApprovedValue(id: string, preApprovedValue: PreApprovedValue): Promise<PreApprovedValue> {
        const request = new HttpRequest(
            ApiRoutes.preApprovedValue.replace('{id}', id),
            PreApprovedValueDTO.toJson(preApprovedValue)
        );

        const response = await this.http.post<PreApprovedValue>(request);
        return PreApprovedValueDTO.fromJson(response);
    }

    async deletePreApprovedValue(proposalId: string, preApprovedValueId: string): Promise<void> {
        const request = new HttpRequest(
            `${ApiRoutes.preApprovedValue.replace('{id}', proposalId)}/${preApprovedValueId}`
        );

        return await this.http.delete(request);
    }


    async uploadProposalDocument(proposalId: string, proposalDocument: ProposalDocument): Promise<void> {
        const request = new HttpRequest(
            ApiRoutes.proposalDocument.replace('{id}', proposalId),
            ProposalDocumentDTO.toJson(proposalDocument)
        );

        return await this.http.post(request);
    }

    async getProposalDocument(id: string): Promise<string> {
        const request = new HttpRequest(
            ApiRoutes.proposalDocument.replace('{id}', id)
        );
        const response = await this.http.get<{ base64: string }>(request);
        return response.base64;
    }

    async addProposalObservation(id: string, observation: string): Promise<void> {
        const request = new HttpRequest(
            ApiRoutes.proposalObservation.replace('{id}', id),
            { body: observation }
        );

        return await this.http.post(request);
    }

    async deleteProposalObservation(proposalId: string, observationId: string): Promise<void> {
        const request = new HttpRequest(
            `${ApiRoutes.proposalObservation.replace('{id}', proposalId)}/${observationId}`
        );

        return await this.http.delete(request);
    }

}