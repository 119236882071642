import { ListSearch } from "../entities/valueObjects/listSearch";

export default class ListSearchDTO {
    static toJson(listSearch: ListSearch): any {
        const json: any = {
            limit: listSearch.limit,
            offset: listSearch.offset,
            searchTerm: listSearch.searchTerm,
            status: listSearch.status,
            orderBy: listSearch.orderBy,
            fromDate: listSearch.fromDate,
            toDate: listSearch.toDate,
            enterpriseId: listSearch.enterpriseId,
        };

        // Remove undefined and empty string properties
        Object.keys(json).forEach(key => {
            if (json[key] === undefined || json[key] === '') {
                delete json[key];
            }
        });

        return json;
    }
}