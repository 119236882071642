import ConstructionCompany from "../../domain/entities/constructionCompany"
import { AxiosHttp } from "../../http/axios"
import ConstructionCompanyRepository from "../repository/constructionCompanyRepository"

export default class ConstructionCompanyProvider {
    private static instance: ConstructionCompanyProvider
    private constructionCompanyRepository: ConstructionCompanyRepository
    private constructionCompanies: ConstructionCompany[] = []

    private constructor() {
        this.constructionCompanyRepository = ConstructionCompanyRepository.getInstance(AxiosHttp.getInstance())
        this.loadConstructionCompanies()
    }

    static getInstance(): ConstructionCompanyProvider {
        if (!ConstructionCompanyProvider.instance) {
            ConstructionCompanyProvider.instance = new ConstructionCompanyProvider()
        }
        return ConstructionCompanyProvider.instance
    }

    private async loadConstructionCompanies() {
        this.constructionCompanies = await this.constructionCompanyRepository.getConstructionCompanies()
    }

    async createConstructionCompany(constructionCompany: ConstructionCompany) {
        const response = await this.constructionCompanyRepository.createConstructionCompany(constructionCompany)
        this.constructionCompanies.push(response)
        return response
    }

    getConstructionCompanies(): ConstructionCompany[] {
        return this.constructionCompanies
    }

    getConstructionCompanyById(id: string): ConstructionCompany | undefined {
        return this.constructionCompanies.find(constructionCompany => constructionCompany.id.toString() === id.toString())
    }
}