import { Address } from "./address";

interface LeadParams {
    id?: string;
    email?: string;
    document?: string;
    phone?: string;
    name?: string;
    motherName?: string;
    monthlyIncome?: number;
    patrimony?: number;
    birthDate?: number;
    createdAt?: number;
    address?: Address;
}

export default class Lead {
    public id: string;
    public email: string;
    public document: string;
    public phone: string;
    public name: string;
    public motherName: string;
    public monthlyIncome: number;
    public patrimony: number;
    public birthDate: number;
    public createdAt: number;
    public address: Address;

    constructor({
        id = '',
        email = '',
        document = '',
        phone = '',
        name = '',
        motherName = '',
        monthlyIncome = 0,
        patrimony = 0,
        birthDate = 0,
        createdAt = 0,
        address = new Address()
    }: LeadParams = {}) {
        this.id = id;
        this.email = email;
        this.document = document;
        this.phone = phone;
        this.name = name;
        this.motherName = motherName;
        this.monthlyIncome = monthlyIncome;
        this.patrimony = patrimony;
        this.birthDate = birthDate;
        this.createdAt = createdAt;
        this.address = address;
    }
}