import { cnpj, cpf } from 'cpf-cnpj-validator'

export class Formatter {
	static clearSymbols = (value: string): string => {
		return value.replace(/[^0-9a-zA-Z]/g, '')
	}

	static clearString = (value: string): string => {
		return value.replace(/\D/g, '')
	}

	static clearNumbers = (value: string): string => {
		return value.replace(/[0-9]/g, '')
	}

	static clearSymbolsAndLetters = (value: string): string => {
		return value.replace(/[^0-9]/g, '')
	}

	static clearSpacesAndSymbols = (value: string): string => {
		return value.replace(/[^0-9a-zA-Z]/g, '')
	}

	static formatNumberToBRCurrency = (value: number | undefined): string => {
		if (!value) return ''
		return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value / 100)
	}

	static formatStringToBRCurrency = (value: string): string => {
		const number = Formatter.strToNumber(value)
		return Formatter.formatNumberToBRCurrency(number)
	}

	static isValidDate = (dateString: string): boolean => {
		const regex = /^\d{4}-\d{2}-\d{2}$/;
		if (!dateString.match(regex)) return false;
		const date = new Date(dateString);
		const timestamp = date.getTime();
		return !isNaN(timestamp);
  	}

	static timeStampToInputDate = (timestamp: number): string => {
		if (timestamp === 0) return ''
		const date = new Date(timestamp * 1000)
		return date.toISOString().split('T')[0]
	}

	static dateStringToTimestamp = (dateString:any): number => {
		if (!Formatter.isValidDate(dateString)) {
		return 0;
		}
		const date = new Date(dateString).toISOString()
		return new Date(date).getTime() / 1000
	}

	// Removendo a hora do nascimento
	static timestampToDateBR = (timestamp: number, withTime = false): string => {
		const date = new Date(timestamp * 1000);
		if (date.getUTCHours() === 0) {
		  date.setUTCHours(3);
		}
	  
		const options: Intl.DateTimeFormatOptions = {
		  year: 'numeric',
		  month: '2-digit',
		  day: '2-digit',
		  ...(withTime && { hour: '2-digit', minute: '2-digit', second: '2-digit' })
		};
		return date.toLocaleString('pt-BR', options);
	  }
	  

	static timestampToTime = (timestamp: number): string => {
		const date = new Date(timestamp * 1000);
		return date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
	}

	static dateToTimestamp = (date: Date): number => {
		return date.getTime() / 1000
	}



	static phoneToBR = (value: string): string => {
		Formatter.clearSymbols(value)
		if (value.length === 13) {
			value = value.slice(0, 12)
		}
		return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
	}

	static strToCpf = (string: string): string => {
		return cpf.format(string)
	}

	static strToCnpj = (string: string): string => {
		return cnpj.format(string)
	}

	static strToCep = (string: string): string => {
		return string.replace(/(\d{5})(\d{3})/, '$1-$2')
	}

	static intToCurrencyK = (value: number): string => {
		value = value / 100
		let formattedValue = ''
		if (value >= 1000) {
			formattedValue = (value / 1000).toFixed(0) + 'k'
		} else {
			formattedValue = value.toFixed(0)
		}
		return formattedValue
	}

	static brlToCents = (value:string): number => {
		value = value.replace(/\D/g, '')
		return parseInt(value) * 100
	}
		

	static intToMonth = (value: number): string => {
		const months = [
			'Janeiro',
			'Fevereiro',
			'Março',
			'Abril',
			'Maio',
			'Junho',
			'Julho',
			'Agosto',
			'Setembro',
			'Outubro',
			'Novembro',
			'Dezembro'
		]
		return months[value - 1]
	}

	static capitalize = (value: string): string => {
		return value.charAt(0).toUpperCase() + value.slice(1)
	}

	static strToNumber = (value: string): number => {
		if (!value) return 0
  		return parseFloat(value.replace(/[^0-9]/g, ''));
	}

	static formatPercentage = (value: number | undefined) => {
		if (!value) return '0.00'
		const formattedValue = (value / 100).toLocaleString("pt-BR", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		});

		return `${formattedValue}`;
  };
}
