import Unit from "../../domain/entities/unit"
import { AxiosHttp } from "../../http/axios"
import UnitRepository from "../repository/unitRepository"

export default class UnitProvider {
    private static instance: UnitProvider
    private unitRepository: UnitRepository
    authErrors: { email: string, password: string } = { email: '', password: '' }

    private constructor() {
        this.unitRepository = UnitRepository.getInstance(AxiosHttp.getInstance())
    }

    static getInstance(): UnitProvider {
        if (!UnitProvider.instance) {
            UnitProvider.instance = new UnitProvider()
        }
        return UnitProvider.instance
    }


    async createEnterpriseUnit(enterpriseId: string, unit: Unit) {
        return await this.unitRepository.createUnit(unit, enterpriseId)
    }

    async updateUnit(unit: Unit) {
        return await this.unitRepository.updateUnit(unit)
    }

    async deleteUnit(unitId: string) {
        return await this.unitRepository.deleteUnit(unitId)
    }
}